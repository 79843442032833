import { isClient } from "@vueuse/shared";

export default defineNuxtRouteMiddleware(async () => {
  const authStore = useAuthStore();
  const cartStore = useCartStore();
  const favoritesStore = useFavoritesStore();

  if (authStore.getAccessToken || authStore.getNumberOfAuthAttempts > 0) return;

  if (isClient) {
    const cookieAnonymousUserId = useCookie("anonymous_user_id", {
      path: "/",
    });

    if (cookieAnonymousUserId.value) {
      authStore.setUserId(cookieAnonymousUserId.value || "");
    }

    const needUpdate = authStore.getIsNeedUpdate;

    if (!needUpdate) {
      return;
    }

    await authStore.updateUserSession();

    if (!authStore.getIsSuccessfullyUpdatedUserSession) {
      authStore.increaseNumberOfAuthAttempts();
      return;
    }
  } else {
    const nuxtApp = useNuxtApp();

    const cookieRefreshToken = useCookie("refresh_token", {
      path: "/",
      httpOnly: true,
    });

    const cookieAnonymousUserId = useCookie("anonymous_user_id", {
      path: "/",
    });

    if (cookieAnonymousUserId.value) {
      authStore.setUserId(cookieAnonymousUserId.value);
    }

    if (!cookieRefreshToken.value) {
      await cartStore.loadCart(nuxtApp);
      await favoritesStore.loadFavoritesList();
      return;
    }

    await authStore.updateUserSession({
      refreshToken: String(cookieRefreshToken.value),
    });

    if (!authStore.getIsSuccessfullyUpdatedUserSession) {
      authStore.increaseNumberOfAuthAttempts();
      return;
    }

    cookieRefreshToken.value = authStore.getRefreshToken;

    authStore.setNeedUpdate(true);

    await authStore.fetchCurrentUserInfo();
    await cartStore.loadCart(nuxtApp);
    await favoritesStore.loadFavoritesList();
  }
});
