export default defineAppConfig({
  ui: {
    colorMode: false,

    colors: {
      primary: "black",
      neutral: "gray",
    },

    button: {
      base: "focus:outline-none focus-visible:outline-0 disabled:cursor-not-allowed disabled:opacity-75 aria-disabled:cursor-not-allowed aria-disabled:opacity-75 flex-shrink-0 transition cursor-pointer",
      variants: {
        size: {
          lg: {
            base: "px-6 py-3",
          },
          xl: {
            base: "px-8 py-3",
          },
        },
      },
    },

    input: {
      slots: {
        root: "w-full",
      },
      defaultVariants: {
        size: "xl",
        color: "neutral",
        variant: "subtle",
      },
    },

    textarea: {
      slots: {
        root: "w-full",
        base: "min-h-[40px]",
      },
      defaultVariants: {
        size: "xl",
        color: "neutral",
        variant: "subtle",
      },
    },

    switch: {
      slots: {
        base: "cursor-pointer",
      },
      defaultVariants: {
        color: "success",
        size: "xl",
      },
    },

    tabs: {
      slots: {
        trigger: "cursor-pointer",
      },
      compoundVariants: [
        {
          color: "neutral",
          variant: "pill",
          class: {
            indicator: "g-primary-3d",
          },
        },
      ],
    },

    breadcrumb: {
      slots: {
        list: "flex-wrap",
        link: "underline-offset-4 hover:text-blue-500 hover:underline",
      },
      variants: {
        active: {
          true: {
            link: "text-(--ui-text-muted) font-medium",
          },
        },
      },
      compoundVariants: [
        {
          disabled: false,
          active: false,
          to: true,
          class: {
            link: "hover:text-blue-500",
          },
        },
      ],
    },
  },

  uiPro: {
    footer: {
      slots: {
        root: "bg-(--ui-bg-elevated) relative",
        top: "border-y border-(--ui-border)",
        center: "lg:pt-8",
      },
    },

    footerColumns: {
      slots: {
        link: "w-fit",
        label: "text-lg",
      },
      variants: {
        active: {
          true: {
            link: "cursor-default",
          },
          false: {
            link: "hover:underline underline-offset-4",
          },
        },
      },
    },

    dashboardPanel: {
      slots: {
        body: "g-admin-content-wrapper",
      },
    },
  },
});
