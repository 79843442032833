import { isClient } from "@vueuse/core";
import type { File as FileEntity } from "~/domain/entity";

export function getS3Path(file?: FileEntity): string {
  if (!file) return "";

  const filePath = `/${file.bucketName}${file.path}`;

  if (!isClient) {
    const s3BaseUrl = String(process.env.NUXT_S3_BASE_URL);
    return filePath ? `${s3BaseUrl}${filePath}` : s3BaseUrl;
  }

  const { NUXT_S3_BASE_URL } = useRuntimeConfig().public;

  return filePath ? `${NUXT_S3_BASE_URL}${filePath}` : NUXT_S3_BASE_URL;
}

export function previewS3File(file?: FileEntity) {
  window.open(getS3Path(file), "_blank");
}

export async function download(url: string, filename: string) {
  const data = await fetch(url);
  const blob = await data.blob();
  const objectUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");

  link.setAttribute("href", objectUrl);
  link.setAttribute("download", filename);
  link.style.display = "none";

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}
