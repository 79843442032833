import validate from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.4.1_@types+node@22.5.4_db0@0.3.1_eslint@9.23.0_jiti@2.4.2_e0c2fe21b58e4305bc04c7535c91c077/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45try_45auth_45global from "/app/app/middleware/01.tryAuth.global.ts";
import _02_45admin_45global from "/app/app/middleware/02.admin.global.ts";
import fix_45scroll_45position_45global from "/app/app/middleware/fix_scroll_position.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.4.1_@types+node@22.5.4_db0@0.3.1_eslint@9.23.0_jiti@2.4.2_e0c2fe21b58e4305bc04c7535c91c077/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45try_45auth_45global,
  _02_45admin_45global,
  fix_45scroll_45position_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/app/middleware/auth.ts"),
  guest: () => import("/app/app/middleware/guest.ts")
}