import { AxiosWrapper } from "~/utils/axios-wrapper";
import {
  createAnonymousUserIdHeader,
  createAuthHeader,
  createReqUpdateAuth,
} from "~/utils/http/axios/interceptors";
import type {
  AddProductToFavoritesRequest,
  AddProductToFavoritesResponse,
} from "~/composables/api/dto/add_product_to_favorites";
import type {
  DeleteProductFromFavoritesRequest,
  DeleteProductFromFavoritesResponse,
} from "~/composables/api/dto/delete_product_from_favorites";
import type {
  GetFavoritesListRequest,
  GetFavoritesListResponse,
} from "~/composables/api/dto/get_favorites_list";

class FavoritesApi extends AxiosWrapper {
  constructor(apiBaseUrl: string) {
    super({
      apiEndpoint: apiBaseUrl,
      config: {
        withCredentials: true,
      },
      interceptors: {
        request: {
          fulfilled: [createAuthHeader, createAnonymousUserIdHeader],
        },
        response: {
          rejected: [createReqUpdateAuth],
        },
      },
    });
  }

  addProductToFavorites(
    data: AddProductToFavoritesRequest,
  ): AddProductToFavoritesResponse {
    return this.post(`${this.apiEndpoint}/api/v1/favorites/${data.productId}`);
  }

  deleteProductFromFavorites(
    data: DeleteProductFromFavoritesRequest,
  ): DeleteProductFromFavoritesResponse {
    return this.delete(
      `${this.apiEndpoint}/api/v1/favorites/${data.productId}`,
    );
  }

  getFavoritesList(data?: GetFavoritesListRequest): GetFavoritesListResponse {
    return this.get(`${this.apiEndpoint}/api/v1/favorites/list`, {
      params: {
        page: data?.page,
        limit: data?.limit,
      },
    });
  }
}

export const useFavoritesApi = createSharedComposable(() => {
  const { NUXT_API_BASE_URL } = useRuntimeConfig().public;

  return new FavoritesApi(NUXT_API_BASE_URL);
});
