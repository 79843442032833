<script setup lang="ts">
import { IconName } from "~/utils/enum/icon_name";
import { PageName } from "~/utils/enum/page_name";
import { useZap } from "~/composables/logger/zap";

const nuxtErr = useError();
const l = useZap("errorPage");

onServerPrefetch(async () => {
  await l.error(nuxtErr.value?.toJSON());
});

const { NUXT_NODE_ENV } = useRuntimeConfig().public;
const isDev = !NUXT_NODE_ENV || NUXT_NODE_ENV === "development";
</script>

<template>
  <NuxtLayout name="auth">
    <div class="relative z-10">
      <div
        class="text-7xl text-center font-medium g-primary-3d hover:opacity-100! text-white p-4 rounded-xl my-4"
      >
        {{ nuxtErr?.statusCode }}
      </div>

      <div
        v-if="!isDev"
        class="text-xl text-center font-medium text-(--ui-text-muted) p-4"
      >
        {{
          nuxtErr?.statusCode === 404
            ? "Страница не найдена"
            : "Возникла ошибка"
        }}
      </div>

      <div
        v-if="isDev"
        class="max-w-[900px] flex flex-col gap-10 bg-(--ui-bg) border border-(--ui-border) py-2 px-3 rounded-xl mt-3"
      >
        <div>
          <h2 class="text-xl font-semibold mb-2">Message</h2>
          <div>{{ nuxtErr?.message }}</div>
        </div>

        <div>
          <h2 class="text-xl font-semibold mb-2">Stack</h2>
          <div class="overflow-auto" v-html="nuxtErr?.stack" />
        </div>
      </div>
      <div
        v-else
        class="text-xl text-center font-medium text-(--ui-text-muted) p-4"
      >
        ¯\_(ツ)_/¯
      </div>

      <div class="flex justify-center items-center mt-12">
        <UButton
          label="На главную"
          size="xl"
          color="neutral"
          variant="subtle"
          :trailing-icon="IconName.ChevronRight"
          :to="{ name: PageName.Home }"
          external
        />
      </div>
    </div>
  </NuxtLayout>
</template>

<style scoped></style>
