
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index4nVS2Dd4a5t9zrU2j8coCxsqtPYupm_GEv_452EzeGhJUMeta } from "/app/app/pages/index.vue?macro=true";
import { default as indexSEpBpmIr05bnkEWtSqU0qogkWE4UWGITltf4Uv1oBkEMeta } from "/app/app/pages/cart/index.vue?macro=true";
import { default as indexBGbi9mJzJJpiRjuRhKvga_450QspKTUlmVO0QyRz6Z3r8Meta } from "/app/app/pages/admin/index.vue?macro=true";
import { default as indexWE4wMYkk0kfNEq1lF5ekfFSKOfWPOgXpSVdie54Jl1wMeta } from "/app/app/pages/search/index.vue?macro=true";
import { default as indexp0Wbi03LwoKhqGNl_45h_oehu2KzdCscs1ZvLTapW5e9MMeta } from "/app/app/pages/account/index.vue?macro=true";
import { default as indexIW2Yfb7nCKwYJIRcGzeeNM9zoFssJJM2zfQ36D7Zry4Meta } from "/app/app/pages/contact/index.vue?macro=true";
import { default as indextuxD9i30ur3bawJlePRY341J_45J7eXdwpjTspY2246F0Meta } from "/app/app/pages/checkout/index.vue?macro=true";
import { default as index5samcvLcnC3xVBkC_PwA3sw1NkLRFJUl2smTnpmdJHAMeta } from "/app/app/pages/delivery/index.vue?macro=true";
import { default as indexcx5JXcnKEjiVaPMNvOctQ6RcpykKsVimtw5htFp9G90Meta } from "/app/app/pages/redirect/index.vue?macro=true";
import { default as indexqctbeECbnudan0PQZK1rHbrGesJJF63Wo3KXzy46iy8Meta } from "/app/app/pages/auth/login/index.vue?macro=true";
import { default as index2A3jthroSGZmYgzePooTFlzlyGenrcgl2bP9lZf22gkMeta } from "/app/app/pages/admin/user/create/index.vue?macro=true";
import { default as relationst2uUncQz3UeDb2PD9hPa_45Psq1wu4hGznkdjX_bKLMV4Meta } from "/app/app/pages/admin/user/create/relations.vue?macro=true";
import { default as create99h_45K1Ju0T4b7lCOG4YoC3JjBMZsP9vZsnj0cpB7StoMeta } from "/app/app/pages/admin/user/create.vue?macro=true";
import { default as indexjBPf90LzcbBee7nPrHcpwp9PiLK380LH8G_MCRi01z8Meta } from "/app/app/pages/admin/user/update/index.vue?macro=true";
import { default as relationsHq8SQL77E1EmJwqAoCpC6yVBoF2E08NdDyiOZn9wd9gMeta } from "/app/app/pages/admin/user/update/relations.vue?macro=true";
import { default as updateIjryuMhP5Xw0uIHDJebSxROf2InnzFDK0FfQJinKjAMMeta } from "/app/app/pages/admin/user/update.vue?macro=true";
import { default as indexhZM4ASGheLqkp0W_8cvX2kMVQqU4yFZGY0DHrV8GSugMeta } from "/app/app/pages/auth/logout/index.vue?macro=true";
import { default as index2pMndysxQVW6tEPuCH0vUVe5YvTxBHtEgj5G3EjOYA0Meta } from "/app/app/pages/all/products/index.vue?macro=true";
import { default as indexOOqy8es1yBZgomkJJZVPtM7GQWzLk5aSwtIbiSEQsR0Meta } from "/app/app/pages/auth/recovery/index.vue?macro=true";
import { default as indexPk7LgbW1SqAC2_45Ty4yoM6s4qOmTz1nQs8u8_K_45uxURQMeta } from "/app/app/pages/auth/register/index.vue?macro=true";
import { default as indexW3YTIoix_45hhKytxgctqETwta7eaIjFXhf6n8ymfHJngMeta } from "/app/app/pages/category/list/index.vue?macro=true";
import { default as indexO_A1_45fx4Budl24BPErbOAdDNVF0MLQvrO9ytN9j0reMMeta } from "/app/app/pages/admin/product/create/index.vue?macro=true";
import { default as relationsl83fqeaXmdbj47gqkO138eVY2WOzmMChe6Y_45IqdG9LoMeta } from "/app/app/pages/admin/product/create/relations.vue?macro=true";
import { default as multimediadqyYxMiw2kvsc1p2_eqDTED0BhNZsx6yC2TDT_455fW5wMeta } from "/app/app/pages/admin/product/create/multimedia.vue?macro=true";
import { default as group_45priceNOPcQsd5jojDomJuj6cjZKo_7esCQFGkIP_45HwWv_RiQMeta } from "/app/app/pages/admin/product/create/group-price.vue?macro=true";
import { default as createYA_7tt4dpoXnc9jhE3MjUBz4wOxTA_2GVOVjaEBlkWkMeta } from "/app/app/pages/admin/product/create.vue?macro=true";
import { default as indexEQtUMqrp5dPT_45L3VHqS1_4502rrnQ_ohU13mCGTZFK7OsMeta } from "/app/app/pages/admin/product/update/index.vue?macro=true";
import { default as relationsTsr1bMAlzPKeOgavIZPIsbGgYWrBvA9W8R45b11SuFMMeta } from "/app/app/pages/admin/product/update/relations.vue?macro=true";
import { default as multimediajS_sbN2Er5j0CHahJH_sr8jIBEIrrgAbCgDfr_l1Yg0Meta } from "/app/app/pages/admin/product/update/multimedia.vue?macro=true";
import { default as group_45priceIGCG5eK7g_sXBY16E_45erWjZp5pEuYVs80XXT9rITq5EMeta } from "/app/app/pages/admin/product/update/group-price.vue?macro=true";
import { default as updateP2SQTBllmf5eY6k7R_UBn9mirDnuUGkOYCkt7wspGoQMeta } from "/app/app/pages/admin/product/update.vue?macro=true";
import { default as indexDlLTKhg6zh0jiD0oSld55kqLfFqh9G_1Qi2P0BE28ZIMeta } from "/app/app/pages/user-agreement/index.vue?macro=true";
import { default as index83aS9aYboussjhQtK7ogK2DFF4BJ6VDpzpYC61h63LQMeta } from "/app/app/pages/account/contact/index.vue?macro=true";
import { default as indexSUre7upX49y7NSIHabx7dEZqwOjbXfDJSz1lX30QAdYMeta } from "/app/app/pages/admin/category/create/index.vue?macro=true";
import { default as createbbXfG5knVuzjUHPXZeX_l0prjoCxheajROyVW_45CCOxwMeta } from "/app/app/pages/admin/category/create.vue?macro=true";
import { default as indexfII1OftyAw7S9hqaKhZ0R2amUXo_WWnUWnKLn_45pkaHIMeta } from "/app/app/pages/admin/category/update/index.vue?macro=true";
import { default as product_45listkT3BIaLP2JD_d_25cMNgIEb_45KVZWGCVGPUKDmbykSNUMeta } from "/app/app/pages/admin/category/update/product-list.vue?macro=true";
import { default as updateolx25QbyjkrIAYr1TLtVpmz2vUKcZaBZgF2oIJ2run4Meta } from "/app/app/pages/admin/category/update.vue?macro=true";
import { default as indexP8wxSCMYIOZVL3CIAZZCZX4sVahZZ6kPGf21J9lvvC4Meta } from "/app/app/pages/admin/user/list/index.vue?macro=true";
import { default as indexjq_45tiGW7Mobq_QIfNkmnPG_45x_45p9e9aehr59IBY6fLUYMeta } from "/app/app/pages/account/password/index.vue?macro=true";
import { default as indexxLvqcn_45bc5Zg8ZPnCOWQZIq6cmNtT6kosBd64DX9dDIMeta } from "/app/app/pages/admin/order/info/index.vue?macro=true";
import { default as indexALCQ83cHSbTwUP0FcxMPxgVYSCyhMOvomwLcEJpg3hMMeta } from "/app/app/pages/admin/order/list/index.vue?macro=true";
import { default as indexpi2JhiZQ48aKoGMWR9efzSgWHIeMm5Dim3pjmKndhHoMeta } from "/app/app/pages/account/favorites/index.vue?macro=true";
import { default as indexaYqEBOc_5aKv5GILnzilKv8dRF1tC2SKWoWSSCvrEA4Meta } from "/app/app/pages/account/order/list/index.vue?macro=true";
import { default as indexNtvrx_uzy3_U7LMQ6Py4pxiHgccfycitYwZ01bCU7EcMeta } from "/app/app/pages/admin/product/list/index.vue?macro=true";
import { default as index05E0i3Gt_4pQOAYMm50NrRp8M9tS6z3CzBVZKSyltVoMeta } from "/app/app/pages/admin/category/list/index.vue?macro=true";
import { default as index6wwyKQ2DK_45lm0471w0mKi_Oh4tPtIYJFnWcHNVFzbqMMeta } from "/app/app/pages/admin/settings/site/index.vue?macro=true";
import { default as indexmkjV4Ol88hGpzJZ23X6bMyFOyQqnU2vQz_454kQS4fHbwMeta } from "/app/app/pages/account/confirm/email/index.vue?macro=true";
import { default as indexpU1w_45rlt5akKMfR5MpciDzRMo2iQBoiHYhmSjnIThg4Meta } from "/app/app/pages/admin/customer/group/create/index.vue?macro=true";
import { default as createf0z7069WOK6tNElYXMe0MIbbEKKSD1dQvux4Y1r2AtEMeta } from "/app/app/pages/admin/customer/group/create.vue?macro=true";
import { default as indexrHHLeZZp8ln4gSjInTAQ4yXZHmGeEA8yXPZ9Q7_456pgcMeta } from "/app/app/pages/admin/customer/group/update/index.vue?macro=true";
import { default as updatee7f1DF3qaPwklP4CGsYyHR8CieadNRW3hFH91315lz0Meta } from "/app/app/pages/admin/customer/group/update.vue?macro=true";
import { default as indexrgA8ZHtRMUKcrPPdAUwFGmSBoBfvzsfqe782HsY1_10Meta } from "/app/app/pages/auth/recovery/[token]/index.vue?macro=true";
import { default as indexKJXhL8BogsL7bTJg90xFJty7rbyMI_rDhciEDPZDyI0Meta } from "/app/app/pages/product/[productSlug]/index.vue?macro=true";
import { default as indexEDYQ3zUN39vzvNp_45EBRibn3NsEM8dfnyXzEFOZetrBQMeta } from "/app/app/pages/application-conditions/index.vue?macro=true";
import { default as index8UX13Ku1IntOR_45CbL989fmg66brhbKKO6mIk5SAMOfAMeta } from "/app/app/pages/account/order/[orderId]/index.vue?macro=true";
import { default as index1pFS2LhIlLnL5NZMZs0YOnWnOHAyEuISDN2g8Txa_m0Meta } from "/app/app/pages/category/[categorySlug]/index.vue?macro=true";
import { default as indexVrxGVQwwrrl8RGpXqVD00B91yyAcEBRjDafCBPUwWnYMeta } from "/app/app/pages/admin/customer/group/list/index.vue?macro=true";
import { default as indexnGyyJ6CXDu5L8EXFsZUPjaLPLs5J_459t_45LLwlZgJa7uoMeta } from "/app/app/pages/checkout/[orderId]/success/index.vue?macro=true";
import { default as indexvkCF23X_lNLcbopm2P6G_45AkbqS7sXSnFYyQ6fTiChsQMeta } from "/app/app/pages/account/confirm/email/[token]/index.vue?macro=true";
import { default as indexftW5ReiTd360WImwb57cwW5iZTMFuquaDfNv4bcDQ_45IMeta } from "/app/app/pages/admin/integrator-request/list/index.vue?macro=true";
import { default as indexh3y0E9Mo3uvVhYB8hSTpERXnbzk_i8_45s40EjqiNYJyAMeta } from "/app/app/pages/category/[categorySlug]/product/[productSlug]/index.vue?macro=true";
export default [
  {
    name: index4nVS2Dd4a5t9zrU2j8coCxsqtPYupm_GEv_452EzeGhJUMeta?.name ?? "index",
    path: "/",
    meta: index4nVS2Dd4a5t9zrU2j8coCxsqtPYupm_GEv_452EzeGhJUMeta || {},
    component: () => import("/app/app/pages/index.vue")
  },
  {
    name: indexSEpBpmIr05bnkEWtSqU0qogkWE4UWGITltf4Uv1oBkEMeta?.name ?? "cart",
    path: "/cart",
    meta: indexSEpBpmIr05bnkEWtSqU0qogkWE4UWGITltf4Uv1oBkEMeta || {},
    component: () => import("/app/app/pages/cart/index.vue")
  },
  {
    name: indexBGbi9mJzJJpiRjuRhKvga_450QspKTUlmVO0QyRz6Z3r8Meta?.name ?? "admin",
    path: "/admin",
    meta: indexBGbi9mJzJJpiRjuRhKvga_450QspKTUlmVO0QyRz6Z3r8Meta || {},
    component: () => import("/app/app/pages/admin/index.vue")
  },
  {
    name: indexWE4wMYkk0kfNEq1lF5ekfFSKOfWPOgXpSVdie54Jl1wMeta?.name ?? "search",
    path: "/search",
    meta: indexWE4wMYkk0kfNEq1lF5ekfFSKOfWPOgXpSVdie54Jl1wMeta || {},
    redirect: indexWE4wMYkk0kfNEq1lF5ekfFSKOfWPOgXpSVdie54Jl1wMeta?.redirect,
    component: () => import("/app/app/pages/search/index.vue")
  },
  {
    name: indexp0Wbi03LwoKhqGNl_45h_oehu2KzdCscs1ZvLTapW5e9MMeta?.name ?? "account",
    path: "/account",
    meta: indexp0Wbi03LwoKhqGNl_45h_oehu2KzdCscs1ZvLTapW5e9MMeta || {},
    component: () => import("/app/app/pages/account/index.vue")
  },
  {
    name: indexIW2Yfb7nCKwYJIRcGzeeNM9zoFssJJM2zfQ36D7Zry4Meta?.name ?? "contact",
    path: "/contact",
    meta: indexIW2Yfb7nCKwYJIRcGzeeNM9zoFssJJM2zfQ36D7Zry4Meta || {},
    component: () => import("/app/app/pages/contact/index.vue")
  },
  {
    name: indextuxD9i30ur3bawJlePRY341J_45J7eXdwpjTspY2246F0Meta?.name ?? "checkout",
    path: "/checkout",
    meta: indextuxD9i30ur3bawJlePRY341J_45J7eXdwpjTspY2246F0Meta || {},
    component: () => import("/app/app/pages/checkout/index.vue")
  },
  {
    name: index5samcvLcnC3xVBkC_PwA3sw1NkLRFJUl2smTnpmdJHAMeta?.name ?? "delivery",
    path: "/delivery",
    meta: index5samcvLcnC3xVBkC_PwA3sw1NkLRFJUl2smTnpmdJHAMeta || {},
    component: () => import("/app/app/pages/delivery/index.vue")
  },
  {
    name: indexcx5JXcnKEjiVaPMNvOctQ6RcpykKsVimtw5htFp9G90Meta?.name ?? "redirect",
    path: "/redirect",
    meta: indexcx5JXcnKEjiVaPMNvOctQ6RcpykKsVimtw5htFp9G90Meta || {},
    component: () => import("/app/app/pages/redirect/index.vue")
  },
  {
    name: indexqctbeECbnudan0PQZK1rHbrGesJJF63Wo3KXzy46iy8Meta?.name ?? "auth-login",
    path: "/auth/login",
    meta: indexqctbeECbnudan0PQZK1rHbrGesJJF63Wo3KXzy46iy8Meta || {},
    component: () => import("/app/app/pages/auth/login/index.vue")
  },
  {
    name: create99h_45K1Ju0T4b7lCOG4YoC3JjBMZsP9vZsnj0cpB7StoMeta?.name,
    path: "/admin/user/create",
    component: () => import("/app/app/pages/admin/user/create.vue"),
    children: [
  {
    name: index2A3jthroSGZmYgzePooTFlzlyGenrcgl2bP9lZf22gkMeta?.name ?? "admin-user-create",
    path: "",
    meta: index2A3jthroSGZmYgzePooTFlzlyGenrcgl2bP9lZf22gkMeta || {},
    component: () => import("/app/app/pages/admin/user/create/index.vue")
  },
  {
    name: relationst2uUncQz3UeDb2PD9hPa_45Psq1wu4hGznkdjX_bKLMV4Meta?.name ?? "admin-user-create-relations",
    path: "relations",
    meta: relationst2uUncQz3UeDb2PD9hPa_45Psq1wu4hGznkdjX_bKLMV4Meta || {},
    component: () => import("/app/app/pages/admin/user/create/relations.vue")
  }
]
  },
  {
    name: updateIjryuMhP5Xw0uIHDJebSxROf2InnzFDK0FfQJinKjAMMeta?.name,
    path: "/admin/user/update",
    component: () => import("/app/app/pages/admin/user/update.vue"),
    children: [
  {
    name: indexjBPf90LzcbBee7nPrHcpwp9PiLK380LH8G_MCRi01z8Meta?.name ?? "admin-user-update",
    path: "",
    meta: indexjBPf90LzcbBee7nPrHcpwp9PiLK380LH8G_MCRi01z8Meta || {},
    component: () => import("/app/app/pages/admin/user/update/index.vue")
  },
  {
    name: relationsHq8SQL77E1EmJwqAoCpC6yVBoF2E08NdDyiOZn9wd9gMeta?.name ?? "admin-user-update-relations",
    path: "relations",
    meta: relationsHq8SQL77E1EmJwqAoCpC6yVBoF2E08NdDyiOZn9wd9gMeta || {},
    component: () => import("/app/app/pages/admin/user/update/relations.vue")
  }
]
  },
  {
    name: indexhZM4ASGheLqkp0W_8cvX2kMVQqU4yFZGY0DHrV8GSugMeta?.name ?? "auth-logout",
    path: "/auth/logout",
    meta: indexhZM4ASGheLqkp0W_8cvX2kMVQqU4yFZGY0DHrV8GSugMeta || {},
    component: () => import("/app/app/pages/auth/logout/index.vue")
  },
  {
    name: index2pMndysxQVW6tEPuCH0vUVe5YvTxBHtEgj5G3EjOYA0Meta?.name ?? "all-products",
    path: "/all/products",
    meta: index2pMndysxQVW6tEPuCH0vUVe5YvTxBHtEgj5G3EjOYA0Meta || {},
    component: () => import("/app/app/pages/all/products/index.vue")
  },
  {
    name: indexOOqy8es1yBZgomkJJZVPtM7GQWzLk5aSwtIbiSEQsR0Meta?.name ?? "auth-recovery",
    path: "/auth/recovery",
    meta: indexOOqy8es1yBZgomkJJZVPtM7GQWzLk5aSwtIbiSEQsR0Meta || {},
    component: () => import("/app/app/pages/auth/recovery/index.vue")
  },
  {
    name: indexPk7LgbW1SqAC2_45Ty4yoM6s4qOmTz1nQs8u8_K_45uxURQMeta?.name ?? "auth-register",
    path: "/auth/register",
    meta: indexPk7LgbW1SqAC2_45Ty4yoM6s4qOmTz1nQs8u8_K_45uxURQMeta || {},
    component: () => import("/app/app/pages/auth/register/index.vue")
  },
  {
    name: indexW3YTIoix_45hhKytxgctqETwta7eaIjFXhf6n8ymfHJngMeta?.name ?? "category-list",
    path: "/category/list",
    meta: indexW3YTIoix_45hhKytxgctqETwta7eaIjFXhf6n8ymfHJngMeta || {},
    component: () => import("/app/app/pages/category/list/index.vue")
  },
  {
    name: createYA_7tt4dpoXnc9jhE3MjUBz4wOxTA_2GVOVjaEBlkWkMeta?.name,
    path: "/admin/product/create",
    component: () => import("/app/app/pages/admin/product/create.vue"),
    children: [
  {
    name: indexO_A1_45fx4Budl24BPErbOAdDNVF0MLQvrO9ytN9j0reMMeta?.name ?? "admin-product-create",
    path: "",
    meta: indexO_A1_45fx4Budl24BPErbOAdDNVF0MLQvrO9ytN9j0reMMeta || {},
    component: () => import("/app/app/pages/admin/product/create/index.vue")
  },
  {
    name: relationsl83fqeaXmdbj47gqkO138eVY2WOzmMChe6Y_45IqdG9LoMeta?.name ?? "admin-product-create-relations",
    path: "relations",
    meta: relationsl83fqeaXmdbj47gqkO138eVY2WOzmMChe6Y_45IqdG9LoMeta || {},
    component: () => import("/app/app/pages/admin/product/create/relations.vue")
  },
  {
    name: multimediadqyYxMiw2kvsc1p2_eqDTED0BhNZsx6yC2TDT_455fW5wMeta?.name ?? "admin-product-create-multimedia",
    path: "multimedia",
    meta: multimediadqyYxMiw2kvsc1p2_eqDTED0BhNZsx6yC2TDT_455fW5wMeta || {},
    component: () => import("/app/app/pages/admin/product/create/multimedia.vue")
  },
  {
    name: group_45priceNOPcQsd5jojDomJuj6cjZKo_7esCQFGkIP_45HwWv_RiQMeta?.name ?? "admin-product-create-group-price",
    path: "group-price",
    meta: group_45priceNOPcQsd5jojDomJuj6cjZKo_7esCQFGkIP_45HwWv_RiQMeta || {},
    component: () => import("/app/app/pages/admin/product/create/group-price.vue")
  }
]
  },
  {
    name: updateP2SQTBllmf5eY6k7R_UBn9mirDnuUGkOYCkt7wspGoQMeta?.name,
    path: "/admin/product/update",
    component: () => import("/app/app/pages/admin/product/update.vue"),
    children: [
  {
    name: indexEQtUMqrp5dPT_45L3VHqS1_4502rrnQ_ohU13mCGTZFK7OsMeta?.name ?? "admin-product-update",
    path: "",
    meta: indexEQtUMqrp5dPT_45L3VHqS1_4502rrnQ_ohU13mCGTZFK7OsMeta || {},
    component: () => import("/app/app/pages/admin/product/update/index.vue")
  },
  {
    name: relationsTsr1bMAlzPKeOgavIZPIsbGgYWrBvA9W8R45b11SuFMMeta?.name ?? "admin-product-update-relations",
    path: "relations",
    meta: relationsTsr1bMAlzPKeOgavIZPIsbGgYWrBvA9W8R45b11SuFMMeta || {},
    component: () => import("/app/app/pages/admin/product/update/relations.vue")
  },
  {
    name: multimediajS_sbN2Er5j0CHahJH_sr8jIBEIrrgAbCgDfr_l1Yg0Meta?.name ?? "admin-product-update-multimedia",
    path: "multimedia",
    meta: multimediajS_sbN2Er5j0CHahJH_sr8jIBEIrrgAbCgDfr_l1Yg0Meta || {},
    component: () => import("/app/app/pages/admin/product/update/multimedia.vue")
  },
  {
    name: group_45priceIGCG5eK7g_sXBY16E_45erWjZp5pEuYVs80XXT9rITq5EMeta?.name ?? "admin-product-update-group-price",
    path: "group-price",
    meta: group_45priceIGCG5eK7g_sXBY16E_45erWjZp5pEuYVs80XXT9rITq5EMeta || {},
    component: () => import("/app/app/pages/admin/product/update/group-price.vue")
  }
]
  },
  {
    name: indexDlLTKhg6zh0jiD0oSld55kqLfFqh9G_1Qi2P0BE28ZIMeta?.name ?? "user-agreement",
    path: "/user-agreement",
    meta: indexDlLTKhg6zh0jiD0oSld55kqLfFqh9G_1Qi2P0BE28ZIMeta || {},
    component: () => import("/app/app/pages/user-agreement/index.vue")
  },
  {
    name: index83aS9aYboussjhQtK7ogK2DFF4BJ6VDpzpYC61h63LQMeta?.name ?? "account-contact",
    path: "/account/contact",
    meta: index83aS9aYboussjhQtK7ogK2DFF4BJ6VDpzpYC61h63LQMeta || {},
    component: () => import("/app/app/pages/account/contact/index.vue")
  },
  {
    name: createbbXfG5knVuzjUHPXZeX_l0prjoCxheajROyVW_45CCOxwMeta?.name,
    path: "/admin/category/create",
    component: () => import("/app/app/pages/admin/category/create.vue"),
    children: [
  {
    name: indexSUre7upX49y7NSIHabx7dEZqwOjbXfDJSz1lX30QAdYMeta?.name ?? "admin-category-create",
    path: "",
    meta: indexSUre7upX49y7NSIHabx7dEZqwOjbXfDJSz1lX30QAdYMeta || {},
    component: () => import("/app/app/pages/admin/category/create/index.vue")
  }
]
  },
  {
    name: updateolx25QbyjkrIAYr1TLtVpmz2vUKcZaBZgF2oIJ2run4Meta?.name,
    path: "/admin/category/update",
    component: () => import("/app/app/pages/admin/category/update.vue"),
    children: [
  {
    name: indexfII1OftyAw7S9hqaKhZ0R2amUXo_WWnUWnKLn_45pkaHIMeta?.name ?? "admin-category-update",
    path: "",
    meta: indexfII1OftyAw7S9hqaKhZ0R2amUXo_WWnUWnKLn_45pkaHIMeta || {},
    component: () => import("/app/app/pages/admin/category/update/index.vue")
  },
  {
    name: product_45listkT3BIaLP2JD_d_25cMNgIEb_45KVZWGCVGPUKDmbykSNUMeta?.name ?? "admin-category-update-product-list",
    path: "product-list",
    meta: product_45listkT3BIaLP2JD_d_25cMNgIEb_45KVZWGCVGPUKDmbykSNUMeta || {},
    component: () => import("/app/app/pages/admin/category/update/product-list.vue")
  }
]
  },
  {
    name: indexP8wxSCMYIOZVL3CIAZZCZX4sVahZZ6kPGf21J9lvvC4Meta?.name ?? "admin-user-list",
    path: "/admin/user/list",
    meta: indexP8wxSCMYIOZVL3CIAZZCZX4sVahZZ6kPGf21J9lvvC4Meta || {},
    component: () => import("/app/app/pages/admin/user/list/index.vue")
  },
  {
    name: indexjq_45tiGW7Mobq_QIfNkmnPG_45x_45p9e9aehr59IBY6fLUYMeta?.name ?? "account-password",
    path: "/account/password",
    meta: indexjq_45tiGW7Mobq_QIfNkmnPG_45x_45p9e9aehr59IBY6fLUYMeta || {},
    component: () => import("/app/app/pages/account/password/index.vue")
  },
  {
    name: indexxLvqcn_45bc5Zg8ZPnCOWQZIq6cmNtT6kosBd64DX9dDIMeta?.name ?? "admin-order-info",
    path: "/admin/order/info",
    meta: indexxLvqcn_45bc5Zg8ZPnCOWQZIq6cmNtT6kosBd64DX9dDIMeta || {},
    component: () => import("/app/app/pages/admin/order/info/index.vue")
  },
  {
    name: indexALCQ83cHSbTwUP0FcxMPxgVYSCyhMOvomwLcEJpg3hMMeta?.name ?? "admin-order-list",
    path: "/admin/order/list",
    meta: indexALCQ83cHSbTwUP0FcxMPxgVYSCyhMOvomwLcEJpg3hMMeta || {},
    component: () => import("/app/app/pages/admin/order/list/index.vue")
  },
  {
    name: indexpi2JhiZQ48aKoGMWR9efzSgWHIeMm5Dim3pjmKndhHoMeta?.name ?? "account-favorites",
    path: "/account/favorites",
    meta: indexpi2JhiZQ48aKoGMWR9efzSgWHIeMm5Dim3pjmKndhHoMeta || {},
    component: () => import("/app/app/pages/account/favorites/index.vue")
  },
  {
    name: indexaYqEBOc_5aKv5GILnzilKv8dRF1tC2SKWoWSSCvrEA4Meta?.name ?? "account-order-list",
    path: "/account/order/list",
    meta: indexaYqEBOc_5aKv5GILnzilKv8dRF1tC2SKWoWSSCvrEA4Meta || {},
    component: () => import("/app/app/pages/account/order/list/index.vue")
  },
  {
    name: indexNtvrx_uzy3_U7LMQ6Py4pxiHgccfycitYwZ01bCU7EcMeta?.name ?? "admin-product-list",
    path: "/admin/product/list",
    meta: indexNtvrx_uzy3_U7LMQ6Py4pxiHgccfycitYwZ01bCU7EcMeta || {},
    component: () => import("/app/app/pages/admin/product/list/index.vue")
  },
  {
    name: index05E0i3Gt_4pQOAYMm50NrRp8M9tS6z3CzBVZKSyltVoMeta?.name ?? "admin-category-list",
    path: "/admin/category/list",
    meta: index05E0i3Gt_4pQOAYMm50NrRp8M9tS6z3CzBVZKSyltVoMeta || {},
    component: () => import("/app/app/pages/admin/category/list/index.vue")
  },
  {
    name: index6wwyKQ2DK_45lm0471w0mKi_Oh4tPtIYJFnWcHNVFzbqMMeta?.name ?? "admin-settings-site",
    path: "/admin/settings/site",
    meta: index6wwyKQ2DK_45lm0471w0mKi_Oh4tPtIYJFnWcHNVFzbqMMeta || {},
    component: () => import("/app/app/pages/admin/settings/site/index.vue")
  },
  {
    name: indexmkjV4Ol88hGpzJZ23X6bMyFOyQqnU2vQz_454kQS4fHbwMeta?.name ?? "account-confirm-email",
    path: "/account/confirm/email",
    meta: indexmkjV4Ol88hGpzJZ23X6bMyFOyQqnU2vQz_454kQS4fHbwMeta || {},
    component: () => import("/app/app/pages/account/confirm/email/index.vue")
  },
  {
    name: createf0z7069WOK6tNElYXMe0MIbbEKKSD1dQvux4Y1r2AtEMeta?.name,
    path: "/admin/customer/group/create",
    component: () => import("/app/app/pages/admin/customer/group/create.vue"),
    children: [
  {
    name: indexpU1w_45rlt5akKMfR5MpciDzRMo2iQBoiHYhmSjnIThg4Meta?.name ?? "admin-customer-group-create",
    path: "",
    meta: indexpU1w_45rlt5akKMfR5MpciDzRMo2iQBoiHYhmSjnIThg4Meta || {},
    component: () => import("/app/app/pages/admin/customer/group/create/index.vue")
  }
]
  },
  {
    name: updatee7f1DF3qaPwklP4CGsYyHR8CieadNRW3hFH91315lz0Meta?.name,
    path: "/admin/customer/group/update",
    component: () => import("/app/app/pages/admin/customer/group/update.vue"),
    children: [
  {
    name: indexrHHLeZZp8ln4gSjInTAQ4yXZHmGeEA8yXPZ9Q7_456pgcMeta?.name ?? "admin-customer-group-update",
    path: "",
    meta: indexrHHLeZZp8ln4gSjInTAQ4yXZHmGeEA8yXPZ9Q7_456pgcMeta || {},
    component: () => import("/app/app/pages/admin/customer/group/update/index.vue")
  }
]
  },
  {
    name: indexrgA8ZHtRMUKcrPPdAUwFGmSBoBfvzsfqe782HsY1_10Meta?.name ?? "auth-recovery-token",
    path: "/auth/recovery/:token()",
    meta: indexrgA8ZHtRMUKcrPPdAUwFGmSBoBfvzsfqe782HsY1_10Meta || {},
    component: () => import("/app/app/pages/auth/recovery/[token]/index.vue")
  },
  {
    name: indexKJXhL8BogsL7bTJg90xFJty7rbyMI_rDhciEDPZDyI0Meta?.name ?? "product-productSlug",
    path: "/product/:productSlug()",
    meta: indexKJXhL8BogsL7bTJg90xFJty7rbyMI_rDhciEDPZDyI0Meta || {},
    component: () => import("/app/app/pages/product/[productSlug]/index.vue")
  },
  {
    name: indexEDYQ3zUN39vzvNp_45EBRibn3NsEM8dfnyXzEFOZetrBQMeta?.name ?? "application-conditions",
    path: "/application-conditions",
    meta: indexEDYQ3zUN39vzvNp_45EBRibn3NsEM8dfnyXzEFOZetrBQMeta || {},
    component: () => import("/app/app/pages/application-conditions/index.vue")
  },
  {
    name: index8UX13Ku1IntOR_45CbL989fmg66brhbKKO6mIk5SAMOfAMeta?.name ?? "account-order-orderId",
    path: "/account/order/:orderId()",
    meta: index8UX13Ku1IntOR_45CbL989fmg66brhbKKO6mIk5SAMOfAMeta || {},
    component: () => import("/app/app/pages/account/order/[orderId]/index.vue")
  },
  {
    name: index1pFS2LhIlLnL5NZMZs0YOnWnOHAyEuISDN2g8Txa_m0Meta?.name ?? "category-categorySlug",
    path: "/category/:categorySlug()",
    meta: index1pFS2LhIlLnL5NZMZs0YOnWnOHAyEuISDN2g8Txa_m0Meta || {},
    component: () => import("/app/app/pages/category/[categorySlug]/index.vue")
  },
  {
    name: indexVrxGVQwwrrl8RGpXqVD00B91yyAcEBRjDafCBPUwWnYMeta?.name ?? "admin-customer-group-list",
    path: "/admin/customer/group/list",
    meta: indexVrxGVQwwrrl8RGpXqVD00B91yyAcEBRjDafCBPUwWnYMeta || {},
    component: () => import("/app/app/pages/admin/customer/group/list/index.vue")
  },
  {
    name: indexnGyyJ6CXDu5L8EXFsZUPjaLPLs5J_459t_45LLwlZgJa7uoMeta?.name ?? "checkout-orderId-success",
    path: "/checkout/:orderId()/success",
    meta: indexnGyyJ6CXDu5L8EXFsZUPjaLPLs5J_459t_45LLwlZgJa7uoMeta || {},
    component: () => import("/app/app/pages/checkout/[orderId]/success/index.vue")
  },
  {
    name: indexvkCF23X_lNLcbopm2P6G_45AkbqS7sXSnFYyQ6fTiChsQMeta?.name ?? "account-confirm-email-token",
    path: "/account/confirm/email/:token()",
    meta: indexvkCF23X_lNLcbopm2P6G_45AkbqS7sXSnFYyQ6fTiChsQMeta || {},
    component: () => import("/app/app/pages/account/confirm/email/[token]/index.vue")
  },
  {
    name: indexftW5ReiTd360WImwb57cwW5iZTMFuquaDfNv4bcDQ_45IMeta?.name ?? "admin-integrator-request-list",
    path: "/admin/integrator-request/list",
    meta: indexftW5ReiTd360WImwb57cwW5iZTMFuquaDfNv4bcDQ_45IMeta || {},
    component: () => import("/app/app/pages/admin/integrator-request/list/index.vue")
  },
  {
    name: indexh3y0E9Mo3uvVhYB8hSTpERXnbzk_i8_45s40EjqiNYJyAMeta?.name ?? "category-categorySlug-product-productSlug",
    path: "/category/:categorySlug()/product/:productSlug()",
    meta: indexh3y0E9Mo3uvVhYB8hSTpERXnbzk_i8_45s40EjqiNYJyAMeta || {},
    component: () => import("/app/app/pages/category/[categorySlug]/product/[productSlug]/index.vue")
  }
]