export function deepCopy<T>(instance: T): T {
  if (instance == null) {
    return instance;
  }

  // handle Dates
  if (instance instanceof Date) {
    return new Date(instance.getTime()) as any;
  }

  // handle Array types
  if (instance instanceof Array) {
    const cloneArr = [] as any[];
    (instance as any[]).forEach((value) => {
      cloneArr.push(value);
    });
    // for nested objects
    return cloneArr.map((value: any) => deepCopy<any>(value)) as any;
  }
  // handle objects
  if (instance instanceof Object) {
    const copyInstance = { ...(instance as { [key: string]: any }) } as {
      [key: string]: any;
    };
    for (const attr in instance) {
      if (Object.prototype.hasOwnProperty.call(instance as object, attr))
        // @ts-expect-error
        copyInstance[attr] = deepCopy<any>(instance[attr]);
    }
    return copyInstance as T;
  }
  // handling primitive data types
  return instance;
}

export function sanitizeApiData<T>(data?: T): T | undefined {
  if (!data) return data;

  const cloned = deepCopy(data);

  for (const key in cloned) {
    if (cloned[key]) {
      if (cloned[key] instanceof Object) {
        // @ts-expect-error
        cloned[key] = sanitizeApiData(cloned[key]);
        continue;
      }

      continue;
    }

    // @ts-expect-error
    cloned[key] = null;
  }

  return cloned;
}

export function deepCopyMap<K, V>(map: Map<K, V>): Map<K, V> {
  return new Map(JSON.parse(JSON.stringify(Array.from(map))));
}

function isEmpty(obj: any): boolean {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

export function isEmptyObject(value: any): boolean {
  if (value == null) {
    // null or undefined
    return false;
  }

  if (typeof value !== "object") {
    // boolean, number, string, function, etc.
    return false;
  }

  const proto = Object.getPrototypeOf(value);

  // consider `Object.create(null)`, commonly used as a safe map
  // before `Map` support, an empty object as well as `{}`
  if (proto !== null && proto !== Object.prototype) {
    return false;
  }

  return isEmpty(value);
}
