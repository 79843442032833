import type { AxiosError } from "axios";
import axios from "axios";
import { useAuthStore } from "~/stores/auth";
import { isClient } from "@vueuse/core";

export const createAuthHeader = (config: any): any => {
  const authStore = useAuthStore();

  config.headers.Authorization = `Bearer ${authStore.getAccessToken}`;

  return config;
};

export const createReqUpdateAuth = async (error: AxiosError) => {
  const originalRequest: any = error?.config;
  const response = error?.response;

  if (response) {
    if (
      (response.status === 401 || response.status === 403) &&
      (originalRequest?.retry < 1 ||
        typeof originalRequest?.retry === "undefined")
    ) {
      if (isClient) {
        // TODO Исправить костыль
        if (originalRequest.url.includes("/api/v1/auth/token/refresh")) {
          return response;
        }

        if (originalRequest.url.includes("/api/v1/auth/login")) {
          return response;
        }

        const authStore = useAuthStore();

        await authStore.updateUserSession();

        if (authStore.getIsSuccessfullyUpdatedUserSession) {
          originalRequest.retry = 1;
          originalRequest.headers.Authorization = `Bearer ${authStore.getAccessToken}`;
          return await axios.request(originalRequest);
        }

        return response;
      }
    }
  }

  return response;
};

export const createReqCSRFTokenHeader = (config: any) => {
  const csrfToken = useCookie("_csrf");

  if (csrfToken.value) {
    config.headers["X-CSRF-Token"] = csrfToken.value;
  }

  return config;
};

export function createCartIdHeader(config: any): any {
  const cartStore = useCartStore();

  if (!cartStore.cart) return config;

  if (!cartStore.cart.id) return config;

  config.headers["X-Cart-Id"] = cartStore.cart.id;

  return config;
}

export function createAnonymousUserIdHeader(config: any): any {
  const authStore = useAuthStore();

  if (authStore.getIsLoggedIn) return config;

  config.headers["X-Anonymous-User-Id"] = authStore.getCurrentUser.id;

  return config;
}
