import type { _Response } from "~/utils/axios-wrapper";
import type { CookieOptions, NuxtApp } from "#app";
import * as cookieUtils from "cookie";

export function setResponseCookies(
  response: _Response<any>,
  nuxtApp?: NuxtApp,
) {
  const cookies = response?.axiosResponse?.headers["set-cookie"];

  if (!cookies) return;

  for (const cookie of cookies) {
    try {
      const pc = cookieUtils.parse(cookie);

      if (pc) {
        // @ts-expect-error
        const [name, value] = cookie.split(";")[0].split("=");

        if (!name) return;

        const options: (CookieOptions & { readonly?: false }) | undefined = {
          path: pc?.Path || "/",
          httpOnly: pc?.HttpOnly !== undefined ? Boolean(pc.HttpOnly) : false,
          secure: pc?.Secure !== undefined ? Boolean(pc.Secure) : false,
          domain: pc?.Domain,
          sameSite: pc?.SameSite as
            | boolean
            | "strict"
            | "lax"
            | "none"
            | undefined,
          maxAge: pc?.MaxAge !== undefined ? Number(pc.MaxAge) : undefined,
          expires: new Date(String(pc?.Expires)),
        };

        if (nuxtApp) {
          nuxtApp.runWithContext(() => {
            const c = useCookie(String(name), options);
            c.value = value;
          });
        } else {
          const c = useCookie(String(name), options);
          c.value = value;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}
