// Code generated by tygo. DO NOT EDIT.

//////////
// source: admin_product.go

export interface AdminProduct {
  id: string /* uuid */;
  createdAt: Date /* RFC3339 */;
  updatedAt: Date /* RFC3339 */;
  isActive: boolean;
  isActiveUpdatedAt: Date /* RFC3339 */;
  sortOrder: number /* uint */;
  price: number /* float64 */;
  priceIsHidden: boolean;
  article?: string;
  title: string;
  description: string;
  seoTitle?: string;
  seoDescription?: string;
  seoKeywords?: string;
  seoUrl?: string;
  multimediaList: ProductMultimediaList;
  deletedAt?: Date /* RFC3339 */;
}
export type AdminProductList = (AdminProduct | undefined)[];

//////////
// source: cart.go

export interface Cart {
  id: string /* uuid */;
  createdAt: Date /* RFC3339 */;
  userId: string /* uuid */;
  cartProductList: CartProductList;
}
export type CartList = (Cart | undefined)[];

//////////
// source: cart_product.go

export interface CartProduct {
  id: string /* uuid */;
  createdAt: Date /* RFC3339 */;
  updatedAt: Date /* RFC3339 */;
  cartId: string /* uuid */;
  quantity: number /* uint64 */;
  product?: CustomerProduct;
}
export type CartProductList = (CartProduct | undefined)[];

//////////
// source: category.go

export interface Category {
  id: string /* uuid */;
  createdAt: Date /* RFC3339 */;
  updatedAt: Date /* RFC3339 */;
  isActive: boolean;
  isActiveUpdatedAt: Date /* RFC3339 */;
  deletedAt?: Date /* RFC3339 */;
  sortOrder: number /* uint */;
  title: string;
  seoTitle?: string;
  seoDescription?: string;
  seoKeywords?: string;
  seoUrl?: string;
  cover?: File;
  pageCover?: File;
  totalProducts: number /* uint */;
}
export type CategoryList = (Category | undefined)[];
export type CategoryListFilterOption = any;

//////////
// source: customer_group.go

export interface CustomerGroup {
  id: string /* uuid */;
  createdAt: Date /* RFC3339 */;
  updatedAt: Date /* RFC3339 */;
  isActive: boolean;
  isActiveUpdatedAt: Date /* RFC3339 */;
  title: string;
}
export type CustomerGroupList = (CustomerGroup | undefined)[];

//////////
// source: customer_product.go

export interface CustomerProduct {
  id: string /* uuid */;
  sortOrder: number /* uint */;
  price: number /* float64 */;
  personalPrice: number /* float64 */;
  article?: string;
  title: string;
  description: string;
  seoTitle?: string;
  seoDescription?: string;
  seoKeywords?: string;
  seoUrl?: string;
  multimediaList: ProductMultimediaList;
  isActive: boolean;
  isDeleted: boolean;
}
export type CustomerProductList = (CustomerProduct | undefined)[];

//////////
// source: customer_user.go

export interface CustomerUser {
  id: string /* uuid */;
  email: string;
  emailIsConfirmed: boolean;
  phone?: string;
  phoneIsConfirmed: boolean;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  isActive: boolean;
  role: UserRole;
  position?: string;
  companyTitle?: string;
  isCustomerGroupAssigned: boolean;
  latestIntegratorRequestStatus?: IntegratorRequestStatus;
  requisitesFileId?: string /* uuid */;
}
export type CustomerUserList = (CustomerUser | undefined)[];

//////////
// source: file.go

export interface File {
  id: string /* uuid */;
  createdAt: Date /* RFC3339 */;
  updatedAt: Date /* RFC3339 */;
  isActive: boolean;
  isActiveUpdatedAt: Date /* RFC3339 */;
  path: string;
  bucketName: string;
  size: number /* uint */;
  title: string;
  extension: string;
  folderId?: string /* uuid */;
  isImage: boolean;
  originalFileId?: string /* uuid */;
  hasWatermark: boolean;
  showInExplorer: boolean;
}
export type FileList = (File | undefined)[];

//////////
// source: folder.go

export interface Folder {
  id: string /* uuid */;
  createdAt: Date /* RFC3339 */;
  updatedAt: Date /* RFC3339 */;
  isActive: boolean;
  isActiveUpdatedAt: Date /* RFC3339 */;
  title: string;
  parentFolderId?: string /* uuid */;
}
export type FolderList = (Folder | undefined)[];

//////////
// source: integrator_request.go

export interface IntegratorRequest {
  id: string /* uuid */;
  createdAt: Date /* RFC3339 */;
  updatedAt: Date /* RFC3339 */;
  status: IntegratorRequestStatus;
  user?: User;
}
export type IntegratorRequestList = (IntegratorRequest | undefined)[];

//////////
// source: integrator_request_status.go

export type IntegratorRequestStatus = string;
export const IntegratorRequestStatusPending: IntegratorRequestStatus = "pending";
export const IntegratorRequestStatusConfirmed: IntegratorRequestStatus = "confirmed";
export const IntegratorRequestStatusRejected: IntegratorRequestStatus = "rejected";

//////////
// source: jwt.go

export interface Jwt {
  accessToken: string;
  refreshToken: string;
}

//////////
// source: jwt_access_token.go

export interface JwtAccessToken {
  userId: string /* uuid */;
}
export interface JwtRegisteredAccessToken {
  JwtAccessToken: JwtAccessToken;
  RegisteredClaims: any /* jwt.RegisteredClaims */;
}

//////////
// source: jwt_refresh_token.go

export interface JwtRefreshToken {
  subject: string;
}
export interface JwtRegisteredRefreshToken {
  JwtRefreshToken: JwtRefreshToken;
  RegisteredClaims: any /* jwt.RegisteredClaims */;
}

//////////
// source: order.go

export interface Order {
  id: string /* uuid */;
  createdAt: Date /* RFC3339 */;
  updatedAt: Date /* RFC3339 */;
  customerUser?: CustomerUser;
  status: OrderStatus;
  orderProductList: OrderProductList;
  freeShippingCost?: string /* float */;
  isFreeShippingConfirmed: boolean;
  customerNote?: string;
  humanlyId: string;
  isFast: boolean;
}
export type OrderList = (Order | undefined)[];

//////////
// source: order_product.go

export interface OrderProduct {
  id: string /* uuid */;
  createdAt: Date /* RFC3339 */;
  updatedAt: Date /* RFC3339 */;
  orderId: string /* uuid */;
  productId: string /* uuid */;
  quantity: number /* uint32 */;
  price: number /* float64 */;
  priceIsHidden: boolean;
  article?: string;
  totalPrice: number /* float64 */;
  productMultimedia?: ProductMultimedia;
  title: string;
  seoUrl?: string;
}
export type OrderProductList = (OrderProduct | undefined)[];

//////////
// source: order_status.go

export type OrderStatus = string;
export const OrderStatusCreated: OrderStatus = "created";
export const OrderStatusCanceled: OrderStatus = "canceled";
export const OrderStatusCompleted: OrderStatus = "completed";

//////////
// source: password_recovery.go

export interface PasswordRecovery {
  id: string /* uuid */;
  createdAt: Date /* RFC3339 */;
  updatedAt: Date /* RFC3339 */;
  isActiveUpdatedAt: Date /* RFC3339 */;
  isActive: boolean;
  userId: string /* uuid */;
}

//////////
// source: product.go

export interface Product {
  id: string /* uuid */;
  createdAt: Date /* RFC3339 */;
  updatedAt: Date /* RFC3339 */;
  isActive: boolean;
  isActiveUpdatedAt: Date /* RFC3339 */;
  sortOrder: number /* uint */;
  price: number /* float64 */;
  priceIsHidden: boolean;
  personalPrice?: number /* float64 */;
  article?: string;
  title: string;
  description: string;
  seoTitle?: string;
  seoDescription?: string;
  seoKeywords?: string;
  seoUrl?: string;
  multimediaList: ProductMultimediaList;
  deletedAt?: Date /* RFC3339 */;
}
export type ProductList = (Product | undefined)[];

//////////
// source: product_customer_group.go

export interface ProductCustomerGroup {
  customerGroup?: CustomerGroup;
  productId: string /* uuid */;
  price?: string /* float */;
}
export type ProductCustomerGroupList = (ProductCustomerGroup | undefined)[];

//////////
// source: product_multimedia.go

export interface ProductMultimedia {
  id: string /* uuid */;
  file?: File;
  productId: string /* uuid */;
  sortOrder: number /* uint */;
  linkedProductMultimediaId?: string /* uuid */;
}
export type ProductMultimediaList = (ProductMultimedia | undefined)[];

//////////
// source: site_settings.go

export interface SiteSettings {
  priceListFile?: File;
  requisitesFile?: File;
  freeShippingCost?: string /* float */;
  thematicTheme: ThematicTheme;
}

//////////
// source: sitemap_url.go

export interface SitemapUrl {
  loc: string;
  lastmod?: Date /* RFC3339 */;
  changefreq?: SitemapUrlChangeFreq;
  priority?: number /* float32 */;
  images: SitemapUrlImageList;
}
export type SitemapUrlList = (SitemapUrl | undefined)[];

//////////
// source: sitemap_url_change_freq.go

export type SitemapUrlChangeFreq = string;
export const SitemapUrlChangeFreqAlways: SitemapUrlChangeFreq = "always";
export const SitemapUrlChangeFreqHourly: SitemapUrlChangeFreq = "hourly";
export const SitemapUrlChangeFreqDaily: SitemapUrlChangeFreq = "daily";
export const SitemapUrlChangeFreqWeekly: SitemapUrlChangeFreq = "weekly";
export const SitemapUrlChangeFreqMonthly: SitemapUrlChangeFreq = "monthly";
export const SitemapUrlChangeFreqYearly: SitemapUrlChangeFreq = "yearly";
export const SitemapUrlChangeFreqNever: SitemapUrlChangeFreq = "never";

//////////
// source: sitemap_url_image.go

export interface SitemapUrlImage {
  loc: string;
  caption?: string;
  title?: string;
}
export type SitemapUrlImageList = (SitemapUrlImage | undefined)[];

//////////
// source: thematic_theme.go

export type ThematicTheme = string;
export const ThematicThemeDefault: ThematicTheme = "default";
export const ThematicThemeNewYear: ThematicTheme = "new_year";

//////////
// source: user.go

export interface User {
  id: string /* uuid */;
  createdAt: Date /* RFC3339 */;
  updatedAt: Date /* RFC3339 */;
  isActive: boolean;
  isActiveUpdatedAt: Date /* RFC3339 */;
  email: string;
  emailIsConfirmed: boolean;
  companyTitle?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  phone?: string;
  phoneIsConfirmed: boolean;
  position?: string;
  sex?: UserSex;
  birthDate?: Date /* RFC3339 */;
  passwordHash: string;
  lastLoginAt?: Date /* RFC3339 */;
  note?: string;
  role: UserRole;
  customerGroup?: CustomerGroup;
  latestIntegratorRequestId?: string /* uuid */;
  latestIntegratorRequestStatus?: IntegratorRequestStatus;
  requisitesFileId?: string /* uuid */;
}
export type UserList = (User | undefined)[];

//////////
// source: user_password.go

export type UserPassword = string;

//////////
// source: user_role.go

export type UserRole = string;
export const UserRoleRoot: UserRole = "root";
export const UserRoleCustomer: UserRole = "customer";
export const UserRoleGuest: UserRole = "guest";

//////////
// source: user_sex.go

export type UserSex = string;
export const UserSexMale: UserSex = "male";
export const UserSexFemale: UserSex = "female";
