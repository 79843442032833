import { AxiosWrapper } from "~/utils/axios-wrapper";
import {
  createAuthHeader,
  createReqUpdateAuth,
} from "~/utils/http/axios/interceptors";
import type { GetSiteSettingsResponse } from "~/composables/api/dto/get_site_settings";
import type {
  UpdatePriceListRequest,
  UpdatePriceListResponse,
} from "~/composables/api/dto/update_price_list";
import type {
  UpdateRequisitesRequest,
  UpdateRequisitesResponse,
} from "~/composables/api/dto/update_requisites";
import type {
  UpdateFreeShippingCostRequest,
  UpdateFreeShippingCostResponse,
} from "~/composables/api/dto/update_free_shipping_cost";
import { sanitizeApiData } from "~/utils/object";
import type {
  UpdateThematicThemeViaAdminRequest,
  UpdateThematicThemeViaAdminResponse,
} from "~/composables/api/dto/update_thematic_theme";

class SiteSettingsApi extends AxiosWrapper {
  constructor(apiBaseUrl: string) {
    super({
      apiEndpoint: apiBaseUrl,
      config: {
        withCredentials: true,
      },
      interceptors: {
        request: {
          fulfilled: [createAuthHeader],
        },
        response: {
          rejected: [createReqUpdateAuth],
        },
      },
    });
  }

  getSiteSettings(): GetSiteSettingsResponse {
    return this.get(`${this.apiEndpoint}/api/v1/site-settings`);
  }

  updatePriceList(data: UpdatePriceListRequest): UpdatePriceListResponse {
    const payload = sanitizeApiData(data);

    return this.patch(
      `${this.apiEndpoint}/api/v1/admin/site-settings/price-list`,
      payload,
    );
  }

  updateRequisites(data: UpdateRequisitesRequest): UpdateRequisitesResponse {
    const payload = sanitizeApiData(data);

    return this.patch(
      `${this.apiEndpoint}/api/v1/admin/site-settings/requisites`,
      payload,
    );
  }

  updateFreeShippingCost(
    data: UpdateFreeShippingCostRequest,
  ): UpdateFreeShippingCostResponse {
    const payload = sanitizeApiData(data);

    return this.patch(
      `${this.apiEndpoint}/api/v1/admin/site-settings/free-shipping-cost`,
      payload,
    );
  }

  updateThematicTheme(
    data: UpdateThematicThemeViaAdminRequest,
  ): UpdateThematicThemeViaAdminResponse {
    const payload = sanitizeApiData(data);

    return this.patch(
      `${this.apiEndpoint}/api/v1/admin/site-settings/thematic-theme`,
      payload,
    );
  }

  downloadPriceList() {
    return this.get(
      `${this.apiEndpoint}/api/v1/site-settings/download/price-list`,
      {
        responseType: "blob",
      },
    );
  }
}

export const useSiteSettingsApi = createSharedComposable(() => {
  const { NUXT_API_BASE_URL } = useRuntimeConfig().public;

  return new SiteSettingsApi(NUXT_API_BASE_URL);
});
