import {
  useZap,
  newApiErr,
  newInvalidResponseError,
  extractErrorCode,
} from "~/composables/logger/zap";
import { useAuthApi } from "~/composables/api/auth";
import { isInvalidResponse } from "~/utils/http/response";
import type { UpdateUserSessionRequest } from "~/composables/api/dto/update_user_session";
import type { LoginRequest } from "~/composables/api/dto/login";
import type { RegisterCustomerRequest } from "~/composables/api/dto/register_customer";
import { useUserApi } from "~/composables/api/user";
import {
  type CustomerUser,
  IntegratorRequestStatusPending,
  IntegratorRequestStatusRejected,
} from "~/domain/entity";
import { ApiErrorCode } from "~/utils/enum/api_error_code";
import { buildCustomerUser } from "~/domain/constructor/customer_user";
import type { UpdateSelfInfoRequest } from "~/composables/api/dto/update_self_info";
import type { UpdateSelfPasswordRequest } from "~/composables/api/dto/update_self_password";
import { UserRoleRoot } from "~/domain/entity";
import { isClient } from "@vueuse/core";

export const useAuthStore = defineStore("authStore", () => {
  const l = useZap("useAuthStore");
  const authApi = useAuthApi();
  const userApi = useUserApi();

  let latestSilentUpdateCurrentUserInfo = new Date();

  const currentUser = ref<CustomerUser>(buildCustomerUser());

  const is = ref({
    needUpdate: false,
    loginLoading: false,
    userInfoFetching: false,
    logoutLoading: false,
    registerLoading: false,

    updateUserSessionLoading: false,
    successfullyUpdatedUserSession: false,

    checkAdminPermissionLoading: false,
    successfullyCheckAdminPermission: false,

    checkUserSessionNeedUpdateLoading: false,

    silentLoadCurrentUserInfoLoading: false,

    userInfoFetched: false,

    admin: false,

    updateSelfInfoLoading: false,
    updateSelfPasswordLoading: false,
  });

  const jwt = ref({
    accessToken: "",
    refreshToken: "",
  });

  const numberOfAuthAttempts = ref(0);

  function resetState() {
    currentUser.value = buildCustomerUser();

    jwt.value.accessToken = "";
    jwt.value.refreshToken = "";

    is.value.loginLoading = false;
    is.value.userInfoFetching = false;
    is.value.logoutLoading = false;
    is.value.updateUserSessionLoading = false;
    is.value.successfullyUpdatedUserSession = false;
    is.value.checkAdminPermissionLoading = false;
    is.value.successfullyCheckAdminPermission = false;
    is.value.checkUserSessionNeedUpdateLoading = false;
    is.value.userInfoFetched = false;
    is.value.admin = false;
    is.value.silentLoadCurrentUserInfoLoading = false;

    numberOfAuthAttempts.value = 0;
  }

  function isSilentLoadCurrentUserInfoDateExpires(): boolean {
    const currentTime = new Date();

    return (
      currentTime.getTime() - latestSilentUpdateCurrentUserInfo.getTime() >
      60_000
    );
  }

  async function login(data: LoginRequest): Promise<number> {
    let apiErrorCode = ApiErrorCode.CodeUnknown;

    if (is.value.loginLoading) return apiErrorCode;

    const _l = l.named("login");

    try {
      is.value.loginLoading = true;

      const res = await authApi.login(data);

      if (isInvalidResponse(res)) {
        apiErrorCode = res.data.error;
        throw new Error(`isInvalidResponse ${JSON.stringify(res)}`);
      }

      if (!res.data.payload.jwt?.accessToken) {
        throw new Error("No JWT accessToken");
      }

      if (!res.data.payload.jwt.refreshToken) {
        throw new Error("No JWT refreshToken");
      }

      jwt.value.accessToken = res.data.payload.jwt.accessToken;
      jwt.value.refreshToken = res.data.payload.jwt.refreshToken;

      is.value.successfullyUpdatedUserSession = true;

      apiErrorCode = ApiErrorCode.CodeNoError;
    } catch (e) {
      await _l.error(e);
    } finally {
      is.value.loginLoading = false;
    }

    return apiErrorCode;
  }

  async function register(data: RegisterCustomerRequest): Promise<number> {
    let apiErrorCode = ApiErrorCode.CodeUnknown;

    if (is.value.registerLoading) return apiErrorCode;

    const _l = l.named("register");

    try {
      is.value.registerLoading = true;

      const res = await authApi.registerCustomer(data);

      if (isInvalidResponse(res)) {
        apiErrorCode = res.data.error;
        throw newApiErr("isInvalidResponse", res);
      }

      if (!res.data.payload.jwt?.accessToken) {
        throw new Error("no jwt accessToken");
      }

      if (!res.data.payload.jwt.refreshToken) {
        throw new Error("no jwt refreshToken");
      }

      jwt.value.accessToken = res.data.payload.jwt.accessToken;
      jwt.value.refreshToken = res.data.payload.jwt.refreshToken;

      is.value.successfullyUpdatedUserSession = true;

      apiErrorCode = ApiErrorCode.CodeNoError;
    } catch (e) {
      await _l.error(e);
    } finally {
      is.value.registerLoading = false;
    }

    return apiErrorCode;
  }

  async function fetchCurrentUserInfo() {
    if (is.value.userInfoFetching) return;

    if (is.value.userInfoFetched) return;

    if (!is.value.successfullyUpdatedUserSession) return;

    const _l = l.named("fetchCurrentUserInfo");

    try {
      is.value.userInfoFetching = true;

      const res = await userApi.getCurrentUserInfo();

      if (isInvalidResponse(res)) {
        throw newApiErr("isInvalidResponse", res);
      }

      if (!res.data.payload) {
        throw new Error("payload is not defined");
      }

      currentUser.value = res.data.payload.customerUser;

      is.value.userInfoFetched = true;
    } catch (e) {
      await _l.error(e);
    } finally {
      is.value.userInfoFetching = false;
    }
  }

  async function checkUserSessionNeedUpdate(): Promise<boolean> {
    if (is.value.checkUserSessionNeedUpdateLoading) return false;

    const _l = l.named("checkUserSessionNeedUpdate");

    try {
      is.value.checkUserSessionNeedUpdateLoading = true;

      const res = await authApi.checkUserSessionNeedUpdate();

      if (isInvalidResponse(res)) {
        throw newApiErr("isInvalidResponse", res);
      }

      return res.data.payload.need;
    } catch (e) {
      await _l.error(e);
    } finally {
      is.value.checkUserSessionNeedUpdateLoading = false;
    }
  }

  async function updateUserSession(data?: UpdateUserSessionRequest) {
    if (is.value.updateUserSessionLoading) return;

    is.value.updateUserSessionLoading = true;

    const _l = l.named("updateUserSession");

    try {
      const res = await authApi.updateUserSession(data);

      if (isInvalidResponse(res)) {
        throw newApiErr("isInvalidResponse", res);
      }

      if (!res.data.payload.jwt?.accessToken) {
        throw new Error("No JWT accessToken");
      }

      if (!res.data.payload.jwt.refreshToken) {
        throw new Error("No JWT refreshToken");
      }

      jwt.value.accessToken = res.data.payload.jwt.accessToken;
      jwt.value.refreshToken = res.data.payload.jwt.refreshToken;

      is.value.successfullyUpdatedUserSession = true;
    } catch (e) {
      is.value.admin = false;
      is.value.successfullyUpdatedUserSession = false;

      await _l.error(e);
    } finally {
      is.value.updateUserSessionLoading = false;
    }
  }

  async function silentLoadCurrentUserInfo(ignoreExpireDate?: boolean) {
    if (!ignoreExpireDate && !isSilentLoadCurrentUserInfoDateExpires()) return;

    if (is.value.silentLoadCurrentUserInfoLoading) return;

    if (!is.value.successfullyUpdatedUserSession) return;

    const _l = l.named("silentLoadCurrentUserInfo");

    try {
      is.value.silentLoadCurrentUserInfoLoading = true;

      const res = await userApi.getCurrentUserInfo();

      if (isInvalidResponse(res)) {
        throw newApiErr("isInvalidResponse", res);
      }

      if (!res.data.payload) {
        throw new Error("payload is not defined");
      }

      if (
        currentUser.value.isCustomerGroupAssigned !==
        res.data.payload.customerUser.isCustomerGroupAssigned
      ) {
        setTimeout(() => {
          window.location.reload();
        }, 50);
      }

      currentUser.value = res.data.payload.customerUser;

      if (isClient) {
        latestSilentUpdateCurrentUserInfo = new Date();
      }
    } catch (e) {
      await _l.error(e);
    } finally {
      is.value.silentLoadCurrentUserInfoLoading = false;
    }
  }

  async function checkAdminPermission(): Promise<boolean> {
    if (is.value.checkAdminPermissionLoading) return false;

    is.value.checkAdminPermissionLoading = true;

    const _l = l.named("checkAdminPermission");

    try {
      const res = await authApi.can();

      if (isInvalidResponse(res)) {
        throw newApiErr("isInvalidResponse", res);
      }

      is.value.admin = true;
      is.value.successfullyCheckAdminPermission = true;

      return true;
    } catch (e) {
      is.value.admin = false;
      is.value.successfullyCheckAdminPermission = false;

      await _l.error(e);

      return false;
    } finally {
      is.value.checkAdminPermissionLoading = false;
    }
  }

  function increaseNumberOfAuthAttempts() {
    numberOfAuthAttempts.value++;
  }

  async function logout(): Promise<boolean> {
    if (is.value.logoutLoading) return false;

    if (!is.value.successfullyUpdatedUserSession) return false;

    const _l = l.named("logout");

    try {
      is.value.logoutLoading = true;

      const res = await authApi.logout();

      if (isInvalidResponse(res)) {
        throw newApiErr("isInvalidResponse", res);
      }

      resetState();

      return true;
    } catch (e) {
      await _l.error(e);
      return false;
    } finally {
      is.value.logoutLoading = false;
    }
  }

  async function updateSelfInfo(data: UpdateSelfInfoRequest): Promise<number> {
    if (is.value.updateSelfInfoLoading) return ApiErrorCode.CodeUnknown;

    is.value.updateSelfInfoLoading = true;

    const _l = l.named("updateSelfInfo");
    let errCode = ApiErrorCode.CodeUnknown;

    try {
      const res = await userApi.updateSelfInfo(data);

      if (isInvalidResponse(res)) {
        errCode = extractErrorCode(res);
        throw newInvalidResponseError(res);
      }

      if (!res.data.payload) {
        throw new Error("payload is not defined");
      }

      if (!res.data.payload?.customerUser) {
        throw new Error("customerUser is not defined");
      }

      currentUser.value = res.data.payload.customerUser;

      errCode = ApiErrorCode.CodeNoError;

      return errCode;
    } catch (e) {
      await _l.error(e);
      return errCode;
    } finally {
      is.value.updateSelfInfoLoading = false;
    }
  }

  async function updateSelfPassword(
    data: UpdateSelfPasswordRequest,
  ): Promise<number> {
    if (is.value.updateSelfPasswordLoading) return ApiErrorCode.CodeUnknown;

    is.value.updateSelfPasswordLoading = true;

    const _l = l.named("updateSelfPassword");
    let errCode = ApiErrorCode.CodeUnknown;

    try {
      const res = await userApi.updateSelfPassword(data);

      if (isInvalidResponse(res)) {
        errCode = extractErrorCode(res);
        throw newInvalidResponseError(res);
      }

      if (!res.data.payload) {
        throw new Error("payload is not defined");
      }

      if (!res.data.payload?.customerUser) {
        throw new Error("customerUser is not defined");
      }

      currentUser.value = res.data.payload.customerUser;

      errCode = ApiErrorCode.CodeNoError;

      return errCode;
    } catch (e) {
      await _l.error(e);
      return errCode;
    } finally {
      is.value.updateSelfPasswordLoading = false;
    }
  }

  function setPhone(phone?: string) {
    currentUser.value.phone = phone;
  }

  function setEmail(email: string) {
    currentUser.value.email = email;
  }

  function setNeedUpdate(needUpdate: boolean) {
    is.value.needUpdate = needUpdate;
  }

  function setUserId(userId: string) {
    currentUser.value.id = userId;
  }

  function needUpdateAnonymousUserId() {
    const cookieAnonymousUserId = useCookie("anonymous_user_id", {
      path: "/",
    });

    if (cookieAnonymousUserId.value) {
      setUserId(cookieAnonymousUserId.value);
    }
  }

  return {
    is,
    currentUser,

    login,
    updateUserSession,
    checkAdminPermission,
    increaseNumberOfAuthAttempts,
    register,
    fetchCurrentUserInfo,
    logout,
    checkUserSessionNeedUpdate,
    silentLoadCurrentUserInfo,
    updateSelfInfo,
    updateSelfPassword,
    setPhone,
    setEmail,
    setNeedUpdate,
    setUserId,
    needUpdateAnonymousUserId,

    getAccessToken: computed(() => jwt.value.accessToken),
    getRefreshToken: computed(() => jwt.value.refreshToken),

    getIsNeedUpdate: computed(() => is.value.needUpdate),

    getIsLoginLoading: computed(() => is.value.loginLoading),
    getIsRegisterLoading: computed(() => is.value.registerLoading),
    getIsUpdateUserSessionLoading: computed(
      () => is.value.updateUserSessionLoading,
    ),
    getIsSuccessfullyUpdatedUserSession: computed(
      () => is.value.successfullyUpdatedUserSession,
    ),
    getIsAdmin: computed(() => is.value.admin),
    getIsLoggedIn: computed(() => !!jwt.value.accessToken),
    getIsUserInfoFetched: computed(() => is.value.userInfoFetched),
    getUserRoleIsAdmin: computed(() => currentUser.value.role === UserRoleRoot),

    getIsUpdateSelfInfoLoading: computed(() => is.value.updateSelfInfoLoading),
    getIsUpdateSelfPasswordLoading: computed(
      () => is.value.updateSelfPasswordLoading,
    ),

    getNumberOfAuthAttempts: computed(() => numberOfAuthAttempts.value),

    getCurrentUser: computed(() => currentUser.value),

    getIsHavePhone: computed(() => !!currentUser.value?.phone),
    getIsNotHavePhone: computed(() => !currentUser.value?.phone),

    getIsHaveRequisites: computed(() => !!currentUser.value?.requisitesFileId),
    getIsNotHaveRequisites: computed(
      () => !currentUser.value?.requisitesFileId,
    ),

    getEmailIsConfirmed: computed(() => currentUser.value?.emailIsConfirmed),
    getEmailIsNotConfirmed: computed(
      () => !currentUser.value?.emailIsConfirmed,
    ),

    getEmail: computed(() => currentUser.value?.email),
    getPhone: computed(() => currentUser.value?.phone),
    getCompanyTitle: computed(() => currentUser.value?.companyTitle),
    getPosition: computed(() => currentUser.value?.position),
    getIsCustomerGroupAssigned: computed(
      () => currentUser.value?.isCustomerGroupAssigned,
    ),
    getLatestIntegratorRequestStatus: computed(
      () => currentUser.value?.latestIntegratorRequestStatus,
    ),
    getCanMakeIntegratorRequest: computed((): boolean => {
      if (!jwt.value.accessToken) {
        return true;
      }

      if (currentUser.value.role === UserRoleRoot) {
        return false;
      }

      const isGroupAssigned = currentUser.value.isCustomerGroupAssigned;

      if (isGroupAssigned) {
        return false;
      }

      const status = currentUser.value.latestIntegratorRequestStatus;

      if (status === IntegratorRequestStatusRejected) {
        return false;
      }

      if (status === IntegratorRequestStatusPending) {
        return false;
      }

      return true;
    }),
  };
});
