export const enum PublicAsset {
  HomeBannerEscort = "/assets/components/home-banner/escort.png",
  HomeBannerDelivery = "/assets/components/home-banner/delivery.jpeg",
  HomeBannerChevrole = "/assets/components/home-banner/chevrole.webp",
  HomeBannerLamba = "/assets/components/home-banner/lamba.webp",

  CtaRepairTrack = "/assets/components/cta/repair-track.webp",
  CtaHandShake = "/assets/components/cta/handshake.webp",

  HeroEscort = "/assets/components/hero/escort.webp",

  DecalStar = "/assets/decals/star.svg",

  PartnerLogoEscort = "/assets/logos/partners/escort.svg",
  PartnerLogoAbro = "/assets/logos/partners/abro.svg",
  PartnerLogoVega = "/assets/logos/partners/vega.svg",
  PartnerLogoDkc = "/assets/logos/partners/dkc.svg",
  PartnerLogoDonedeal = "/assets/logos/partners/donedeal.svg",
  PartnerLogoKvt = "/assets/logos/partners/kvt.svg",
  PartnerLogoMta = "/assets/logos/partners/mta.svg",
  PartnerLogoNavtelekom = "/assets/logos/partners/navtelekom.svg",
  PartnerLogoRexat = "/assets/logos/partners/rexat.svg",
  PartnerLogoTekcell = "/assets/logos/partners/tekcell.svg",
  PartnerLogoTerminator = "/assets/logos/partners/terminator.svg",
  PartnerLogoTitan = "/assets/logos/partners/titan.svg",
  PartnerLogoXeno = "/assets/logos/partners/xeno.svg",

  AlvimaLogo = "/assets/logos/alvima/alvima.svg",
  AlvimaLogoPng = "/assets/logos/alvima/alvima.png",
  AlvimaLogoThematicNewYear = "/assets/logos/alvima/alvima-thematic-new-year.svg",
  AlvimaFavicon = "/favicon-96x96.png",
}
