export const enum ApiErrorCode {
  CodeUnknown,
  CodeNoError,
  CodeCategoryAlreadyDeleted,
  CodeProductAlreadyDeleted,
  CodeUniqueViolation,
  CodeNoPermission,
  CodeRecordNotFound,
  CodeFolderIsUsed,
  CodeSeoUrlIsExists,
  CodeUserNotCustomer,
  CodeFileNotImage,
  CodeForeignKeyViolation,
  CodeCheckViolation,
  CodeUserNotInCustomerGroup,
  CodeProductCannotBePurchased,
  CodeCartIsEmpty,
  CodeUserNotOrderOwner,
  CodeFileIsUsed,
  CodeEmailIsNotConfirmed,
  CodeInvalidPassword,
  CodeUserHasNoPhoneNumber,
  CodeInvalidFreeShippingCost,
  CodeEmailIsAlreadyConfirmed,
  CodeInvalidCredentials,
  CodeUserPasswordMismatch,
  CodeUserIdentifyNotPossible,
  CodeUserIsGuest,
  CodeProductArticleAlreadyExists,
  CodeEmailAlreadyExists,
  CodeBothCartsNotFound,
  CodeCartNotFound,
}
