export const enum IconName {
  Plus = "i-heroicons-plus",
  PlusSmall = "i-heroicons:plus-small",

  MinusSmall = "i-heroicons:minus-small",

  CalendarDays = "i-heroicons-calendar-days-20-solid",

  Trash = "i-heroicons:trash",

  EllipsisVertical = "i-heroicons-ellipsis-vertical",

  Adjustments = "i-heroicons:adjustments-horizontal-solid",

  Pencil = "i-heroicons:pencil",
  PencilSquare = "i-heroicons:pencil-square",

  Squares = "i-heroicons:squares-2x2",

  Photo = "i-heroicons:photo",

  Eye = "i-heroicons:eye",
  EyeDropperSolid = "i-heroicons:eye-dropper-solid",

  RectangleStack = "i-heroicons:rectangle-stack",

  ArrowPath = "i-heroicons:arrow-path",
  ArrowLeftCircle = "i-heroicons:arrow-left-circle",
  ArrowUpSquare = "i-heroicons:arrow-up-on-square",
  ArrowSmallRight = "i-heroicons:arrow-small-right-20-solid",
  ArrowSmallLeft = "i-heroicons:arrow-small-left-20-solid",
  ArrowRight = "i-heroicons:arrow-right",
  ArrowLeft = "i-heroicons:arrow-left",
  ArrowRightEndOnRectangle = "i-heroicons:arrow-right-end-on-rectangle",
  ArrowRightStartOnRectangle = "i-heroicons:arrow-right-start-on-rectangle",
  ArrowTopRightOnSquare = "i-heroicons:arrow-top-right-on-square",

  ChevronLeft = "i-heroicons:chevron-left-16-solid",
  ChevronRight = "i-heroicons:chevron-right-16-solid",
  ChevronDown = "i-heroicons:chevron-down-16-solid",

  DocumentArrowUp = "i-heroicons:document-arrow-up",

  Folder = "i-heroicons:folder",
  FolderPlus = "i-heroicons:folder-plus",

  DocumentSolid = "i-heroicons:document-solid",

  Home = "i-heroicons-home",
  CategoryList = "i-heroicons-cube",
  ProductList = "i-heroicons-building-storefront",
  OrderList = "i-heroicons-circle-stack",

  User = "i-heroicons:user",
  Users = "i-heroicons:users",
  UserPlus = "i-heroicons:user-plus",
  UserGroup = "i-heroicons-user-group",
  UserCircle = "i-heroicons:user-circle",

  SiteSettings = "i-heroicons-cog-8-tooth",

  Bold = "i-heroicons:bold-solid",
  Italic = "i-heroicons:italic-20-solid",
  Strike = "i-heroicons:strikethrough",
  BulletList = "i-heroicons:list-bullet-16-solid",
  OrderedList = "i-heroicons:numbered-list-20-solid",
  Undo = "i-heroicons:arrow-uturn-left-20-solid",
  Redo = "i-heroicons:arrow-uturn-right-20-solid",

  File = "i-ri:file-2-line",
  FileExcel = "i-ri:file-excel-2-line",
  FileWord = "i-ri:file-word-2-line",

  SearchLine = "i-ri:search-line",

  ShoppingCart = "i-heroicons:shopping-cart",

  Phone = "i-ph:phone",

  Airplane = "i-ph:airplane-taxiing",

  MagnifyingGlass = "i-heroicons-magnifying-glass-20-solid",

  Dollar = "i-heroicons:currency-dollar",

  Banknote = "i-heroicons:banknotes",

  GlobeAmericas = "i-heroicons:globe-americas-solid",

  HandThumbUp = "i-heroicons:hand-thumb-up",
  HandThumbUpSolid = "i-heroicons:hand-thumb-up-solid",

  AcademicCap = "i-heroicons:academic-cap",

  BuildingStorefront = "i-heroicons:building-storefront",

  ClipboardDocumentList = "i-heroicons:clipboard-document-list",

  FaceSmile = "i-heroicons:face-smile",

  Envelope = "i-heroicons:envelope",

  MapPin = "i-heroicons:map-pin",

  QuestionMarkCircle = "i-heroicons:question-mark-circle",

  QueueList = "i-heroicons:queue-list",
  QueueListSolid = "i-heroicons:queue-list-16-solid",

  XMark = "i-heroicons-x-mark-20-solid",

  FingerPrint = "i-heroicons:finger-print",

  ShoppingBag = "i-heroicons:shopping-bag",

  Clock = "i-heroicons:clock",

  Check = "i-heroicons:check",
  CheckBadge = "i-heroicons:check-badge",
  CheckCircle = "i-heroicons:check-circle",

  ShieldCheck = "i-heroicons:shield-check",

  AtSymbol = "i-heroicons:at-symbol",

  InformationCircle = "i-heroicons:information-circle",

  ExclamationCircle = "i-heroicons:exclamation-circle",

  Tag = "i-heroicons:tag",

  CursorArrowRipple = "i-heroicons:cursor-arrow-ripple",

  RocketLaunch = "i-heroicons:rocket-launch",

  BuildingOffice2 = "i-heroicons:building-office-2",

  Bolt = "i-heroicons:bolt",
  BoltSolid = "i-heroicons:bolt-solid",

  Trophy = "i-heroicons:trophy",

  Truck = "i-heroicons:truck",

  Star = "i-heroicons:star",

  Link = "i-heroicons:link-20-solid",

  Heart = "i-heroicons:heart",
  HeartSolid = "i-heroicons:heart-solid",

  LockClosed = "i-heroicons:lock-closed",

  FloppyDisc = "flowbite:floppy-disk-alt-outline",
}
