import { AxiosWrapper } from "~/utils/axios-wrapper";
import {
  createAuthHeader,
  createReqUpdateAuth,
} from "~/utils/http/axios/interceptors";
import type {
  CreateUserViaAdminRequest,
  CreateUserViaAdminResponse,
} from "~/composables/api/dto/create_user";
import type {
  UpdateUserViaAdminRequest,
  UpdateUserViaAdminResponse,
} from "~/composables/api/dto/update_user";
import type {
  GetUserListViaAdminRequest,
  GetUserListViaAdminResponse,
} from "~/composables/api/dto/get_user_list";
import type {
  GetUserByIdViaAdminRequest,
  GetUserByIdViaAdminResponse,
} from "~/composables/api/dto/get_user_by_id";
import type { GetCurrentUserInfoResponse } from "~/composables/api/dto/get_current_user_info";
import type {
  ConfirmEmailRequest,
  ConfirmEmailResponse,
} from "~/composables/api/dto/confirm_email";
import type { SendEmailConfirmationMailResponse } from "~/composables/api/dto/send_email_confirmation_mail";
import { sanitizeApiData } from "~/utils/object";
import type {
  UpdateSelfInfoRequest,
  UpdateSelfInfoResponse,
} from "~/composables/api/dto/update_self_info";
import type {
  UpdateSelfPasswordRequest,
  UpdateSelfPasswordResponse,
} from "~/composables/api/dto/update_self_password";
import type { DownloadUserRequisitesViaAdminRequest } from "~/composables/api/dto/download_user_requisites_via_admin";

class UserApi extends AxiosWrapper {
  constructor(apiBaseUrl: string) {
    super({
      apiEndpoint: apiBaseUrl,
      config: {
        withCredentials: true,
      },
      interceptors: {
        request: {
          fulfilled: [createAuthHeader],
        },
        response: {
          rejected: [createReqUpdateAuth],
        },
      },
    });
  }

  createUserViaAdmin(
    data: CreateUserViaAdminRequest,
  ): CreateUserViaAdminResponse {
    const sData = sanitizeApiData(data);
    return this.post(`${this.apiEndpoint}/api/v1/admin/user`, sData);
  }

  updateUserViaAdmin(
    data: UpdateUserViaAdminRequest,
  ): UpdateUserViaAdminResponse {
    const sData = sanitizeApiData(data);
    return this.patch(`${this.apiEndpoint}/api/v1/admin/user`, sData);
  }

  getUserListViaAdmin(
    data: GetUserListViaAdminRequest,
  ): GetUserListViaAdminResponse {
    const sData = sanitizeApiData(data);

    return this.get(`${this.apiEndpoint}/api/v1/admin/user/list`, {
      params: {
        page: sData?.pagination?.page,
        limit: sData?.pagination?.limit,
        searchQuery: sData?.searchQuery,
      },
    });
  }

  getUserByIdViaAdmin(
    data: GetUserByIdViaAdminRequest,
  ): GetUserByIdViaAdminResponse {
    return this.get(`${this.apiEndpoint}/api/v1/admin/user/${data.userId}`);
  }

  getCurrentUserInfo(): GetCurrentUserInfoResponse {
    return this.get(`${this.apiEndpoint}/api/v1/user/current`);
  }

  sendEmailConfirmationMail(): SendEmailConfirmationMailResponse {
    return this.patch(
      `${this.apiEndpoint}/api/v1/user/current/confirm/email/send`,
    );
  }

  confirmEmail(data: ConfirmEmailRequest): ConfirmEmailResponse {
    return this.patch(
      `${this.apiEndpoint}/api/v1/user/confirm/email/token/${data.token}`,
      data,
    );
  }

  updateSelfInfo(data: UpdateSelfInfoRequest): UpdateSelfInfoResponse {
    const formData = new FormData();

    for (const key in data) {
      if (!data[key]) continue;

      formData.append(key, data[key]);
    }

    return this.patch(
      `${this.apiEndpoint}/api/v1/user/current/update/info`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
  }

  updateSelfPassword(
    data: UpdateSelfPasswordRequest,
  ): UpdateSelfPasswordResponse {
    const payload = sanitizeApiData(data);
    return this.patch(
      `${this.apiEndpoint}/api/v1/user/current/update/password`,
      payload,
    );
  }

  downloadUserRequisitesViaAdmin(data: DownloadUserRequisitesViaAdminRequest) {
    return this.get(
      `${this.apiEndpoint}/api/v1/admin/user/${data.userId}/requisites`,
      {
        responseType: "blob",
      },
    );
  }
}

export const useUserApi = createSharedComposable(() => {
  const { NUXT_API_BASE_URL } = useRuntimeConfig().public;

  return new UserApi(NUXT_API_BASE_URL);
});
