import { ApiErrorCode } from "~/utils/enum/api_error_code";
import { isClient } from "@vueuse/core";
import { IconName } from "~/utils/enum/icon_name";

export function createErrorToast(message?: string, duration?: number) {
  const toast = useToast();

  toast.add({
    icon: IconName.InformationCircle,
    title: message ? message : "Возникла ошибка",
    color: "error",
    duration: duration ? duration : 6000,
  });
}

export function createSuccessToast(message?: string, duration?: number) {
  const toast = useToast();

  toast.add({
    icon: "i-heroicons-check-circle",
    title: message ? message : "Успешно выполнено",
    color: "success",
    duration: duration ? duration : 6000,
  });
}

export function dynamicToast(error?: any) {
  if (!isClient) {
    return;
  }

  if (isNaN(error)) {
    createErrorToast(`Возникла ошибка: ${error}`);
    return;
  }

  const errCode = Number(error);

  switch (errCode) {
    case ApiErrorCode.CodeFolderIsUsed: {
      createErrorToast("Папка должна быть пустой");
      break;
    }

    case ApiErrorCode.CodeFileIsUsed: {
      createErrorToast("Файл не может быть удален, поскольку он используется");
      break;
    }

    default:
      createErrorToast();
      break;
  }
}
