import { PageName } from "~/utils/enum/page_name";
import { LayoutName } from "~/utils/enum/layout_name";

export default defineNuxtRouteMiddleware(async (to) => {
  if (to?.meta.layout !== LayoutName.Admin) {
    return;
  }

  // Предохранитель от цикла
  if (to?.name === PageName.Home.toString()) {
    return;
  }

  const authStore = useAuthStore();

  if (!authStore.getIsSuccessfullyUpdatedUserSession) {
    return navigateTo({
      name: PageName.Home.toString(),
    });
  }

  if (!authStore.getIsAdmin) {
    const success = await authStore.checkAdminPermission();

    if (!success) {
      return navigateTo({
        name: PageName.Home.toString(),
      });
    }
  }
});
