import type { CustomerUser } from "~/domain/entity";
import { UserRoleCustomer } from "~/domain/entity";

export function buildCustomerUser(): CustomerUser {
  return {
    id: "",
    email: "",
    emailIsConfirmed: false,
    phone: undefined,
    phoneIsConfirmed: false,
    firstName: undefined,
    middleName: undefined,
    lastName: undefined,
    isActive: false,
    role: UserRoleCustomer,
  };
}
