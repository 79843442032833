import { AxiosWrapper } from "~/utils/axios-wrapper";
import {
  createAuthHeader,
  createReqUpdateAuth,
} from "~/utils/http/axios/interceptors";
import type {
  LoggerPushRequest,
  LoggerPushResponse,
} from "~/composables/api/dto/logger_push";

class LoggerApi extends AxiosWrapper {
  constructor(apiBaseUrl: string) {
    super({
      apiEndpoint: apiBaseUrl,
      config: {
        withCredentials: true,
      },
      interceptors: {
        request: {
          fulfilled: [createAuthHeader],
        },
        response: {
          rejected: [createReqUpdateAuth],
        },
      },
    });
  }

  push(data: LoggerPushRequest): LoggerPushResponse {
    return this.post(`${this.apiEndpoint}/api/v1/logger/push`, data);
  }
}

export const useLoggerApi = createSharedComposable(() => {
  const { NUXT_API_BASE_URL } = useRuntimeConfig().public;

  return new LoggerApi(NUXT_API_BASE_URL);
});
