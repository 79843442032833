export function numberWithSpaces(
  x: string | number,
  spaceValue?: string,
): string {
  if (!x) return "";

  const _spaceValue = spaceValue ? spaceValue : " ";

  const parts = Number(Math.round(Number(x) * 100) / 100)
    .toString()
    .split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, _spaceValue);
  return parts.join(".");
}
