import type { Cart, CartProduct } from "~/domain/entity";

export function buildCart(): Cart {
  return {
    id: "",
    createdAt: new Date(),
    userId: "",
    cartProductList: [] as CartProduct[],
  };
}
