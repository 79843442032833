import type { ResponseDto } from "~/composables/api/dto/response";
import type { _Response } from "~/utils/axios-wrapper/types";
import { ApiErrorCode } from "~/utils/enum/api_error_code";
import { useLoggerApi } from "~/composables/api/logger";

export interface IZapConfig {
  name?: string;
  url?: string;
}

export function newApiErr(message: any, res: _Response<ResponseDto>): Error {
  return new Error(`${message} - ${JSON.stringify(res?.data?.error)}`);
}

export function newInvalidResponseError(res: _Response<ResponseDto>): Error {
  return newApiErr("invalid response", res);
}

export function extractErrorCode(res: _Response<ResponseDto>): number {
  if (res && res.data && res.data.error) {
    return typeof Number(res.data.error) === "number"
      ? Number(res.data.error)
      : ApiErrorCode.CodeNoError;
  }

  return ApiErrorCode.CodeUnknown;
}

export const useZap = (name?: string | IZapConfig) => {
  const loggerApi = useLoggerApi();

  const getName = () => {
    if (typeof name === "string") {
      return name;
    }
    return name?.name ?? "";
  };

  const lName = getName();

  const named = (name: string) => {
    return useZap(`${lName}: ${name}`);
  };

  const log = async (message?: any, ...optionalParams: any[]) => {
    console.log(lName, message, ...optionalParams);
  };

  const error = async (message?: any, ...optionalParams: any[]) => {
    console.error(lName, message, ...optionalParams);

    const jsonMsg = JSON.stringify(String(message));

    try {
      await loggerApi.push({
        level: "error",
        placement: lName,
        payload: jsonMsg,
      });
    } catch (e) {
      console.error(lName, e);
    }
  };

  const debug = async (message?: any, ...optionalParams: any[]) => {
    // TODO Отключить в проде
    console.log(lName, message, ...optionalParams);
  };

  return {
    named,
    log,
    error,
    debug,
  };
};
