import {
  extractErrorCode,
  newInvalidResponseError,
  useZap,
} from "~/composables/logger/zap";
import { useFavoritesApi } from "~/composables/api/favorites";
import type { CustomerProduct } from "~/domain/entity";
import { isInvalidResponse } from "~/utils/http/response";
import { ApiErrorCode } from "~/utils/enum/api_error_code";

export const useFavoritesStore = defineStore("favoritesStore", () => {
  const l = useZap("useFavoritesStore");

  const favoritesApi = useFavoritesApi();
  const favoritesList = ref<Map<string, CustomerProduct>>(new Map());
  const is = ref({
    loading: false,
  });

  async function addProductToFavorites(
    product: CustomerProduct,
  ): Promise<number> {
    if (is.value.loading) return ApiErrorCode.CodeUnknown;

    is.value.loading = true;

    const _l = l.named("addProductToFavorites");
    let errCode = ApiErrorCode.CodeUnknown;

    try {
      const res = await favoritesApi.addProductToFavorites({
        productId: product.id,
      });

      if (isInvalidResponse(res)) {
        errCode = extractErrorCode(res);
        throw newInvalidResponseError(res);
      }

      favoritesList.value.set(product.id, product);

      return ApiErrorCode.CodeNoError;
    } catch (e) {
      await _l.error(e);
      return errCode;
    } finally {
      is.value.loading = false;
    }
  }

  async function deleteProductFromFavorites(
    productId: string,
  ): Promise<number> {
    if (is.value.loading) return ApiErrorCode.CodeUnknown;

    is.value.loading = true;

    const _l = l.named("deleteProductFromFavorites");
    let errCode = ApiErrorCode.CodeUnknown;

    try {
      const res = await favoritesApi.deleteProductFromFavorites({
        productId: productId,
      });

      if (isInvalidResponse(res)) {
        errCode = extractErrorCode(res);
        throw newInvalidResponseError(res);
      }

      favoritesList.value.delete(productId);

      return ApiErrorCode.CodeNoError;
    } catch (e) {
      await _l.error(e);
      return errCode;
    } finally {
      is.value.loading = false;
    }
  }

  async function toggleProductFavorites(
    product: CustomerProduct,
  ): Promise<number> {
    if (favoritesList.value.has(product.id)) {
      return await deleteProductFromFavorites(product.id);
    }

    return await addProductToFavorites(product);
  }

  async function loadFavoritesList(): Promise<number> {
    if (is.value.loading) return ApiErrorCode.CodeUnknown;

    is.value.loading = true;

    const _l = l.named("loadFavoritesList");
    let errCode = ApiErrorCode.CodeUnknown;

    try {
      const res = await favoritesApi.getFavoritesList();

      if (isInvalidResponse(res)) {
        errCode = extractErrorCode(res);
        throw newInvalidResponseError(res);
      }

      favoritesList.value = new Map(
        res.data.payload.productList.map((p: CustomerProduct) => [p.id, p]),
      );

      return ApiErrorCode.CodeNoError;
    } catch (e) {
      await _l.error(e);
      return errCode;
    } finally {
      is.value.loading = false;
    }
  }

  function productInFavorites(productId: string): boolean {
    return favoritesList.value.has(productId);
  }

  return {
    favoritesList,
    is,

    addProductToFavorites,
    deleteProductFromFavorites,
    loadFavoritesList,
    productInFavorites,
    toggleProductFavorites,

    getFavoritesList: computed(
      (): Map<string, CustomerProduct> => favoritesList.value,
    ),
    getFavoritesListArray: computed((): CustomerProduct[] =>
      Array.from(favoritesList.value.values()),
    ),
    getIsLoading: computed((): boolean => is.value.loading),
  };
});
