import { AxiosWrapper } from "~/utils/axios-wrapper";
import type { LoginRequest, LoginResponse } from "~/composables/api/dto/login";
import type {
  RegisterCustomerRequest,
  RegisterCustomerResponse,
} from "~/composables/api/dto/register_customer";
import type {
  UpdateUserSessionRequest,
  UpdateUserSessionResponse,
} from "~/composables/api/dto/update_user_session";
import type {
  InitiatePasswordRecoveryRequest,
  InitiatePasswordRecoveryResponse,
} from "~/composables/api/dto/initiate_password_recovery";
import type {
  ValidatePasswordRecoveryRequest,
  ValidatePasswordRecoveryResponse,
} from "~/composables/api/dto/validate_password_recovery";
import type {
  UpdateUserPasswordAfterRecoveryRequest,
  UpdateUserPasswordAfterRecoveryResponse,
} from "~/composables/api/dto/update_user_password_after_recovery";
import {
  createAuthHeader,
  createReqUpdateAuth,
} from "~/utils/http/axios/interceptors";
import type { CheckUserSessionNeedUpdateResponse } from "~/composables/api/dto/check_user_session_need_update";
import { sanitizeApiData } from "~/utils/object";

class AuthApi extends AxiosWrapper {
  constructor(apiBaseUrl: string) {
    super({
      apiEndpoint: apiBaseUrl,
      config: {
        withCredentials: true,
      },
      interceptors: {
        request: {
          fulfilled: [createAuthHeader],
        },
        response: {
          rejected: [createReqUpdateAuth],
        },
      },
    });
  }

  /**
   * Войти в аккаунт
   * @param data Данные для входа в аккаунт
   */
  login(data: LoginRequest): LoginResponse {
    const payload = sanitizeApiData(data);
    return this.post(`${this.apiEndpoint}/api/v1/auth/login`, payload);
  }

  /**
   * Выйти из аккаунта
   */
  logout() {
    return this.post(`${this.apiEndpoint}/api/v1/auth/logout`);
  }

  /**
   * Зарегистрировать новый аккаунт покупателя
   * @param data Данные для регистрации нового аккаунта покупателя
   */
  registerCustomer(data: RegisterCustomerRequest): RegisterCustomerResponse {
    const payload = sanitizeApiData(data);
    return this.post(
      `${this.apiEndpoint}/api/v1/auth/register/customer`,
      payload,
    );
  }

  /**
   * Обновить сессию пользователя (JWT токены)
   * @param data Если нужно передать Refresh Token через body, а не через Cookie
   */
  updateUserSession(
    data?: UpdateUserSessionRequest,
  ): UpdateUserSessionResponse {
    const payload = sanitizeApiData(data);
    return this.post(`${this.apiEndpoint}/api/v1/auth/token/refresh`, payload);
  }

  initiatePasswordRecovery(
    data: InitiatePasswordRecoveryRequest,
  ): InitiatePasswordRecoveryResponse {
    return this.post(`${this.apiEndpoint}/api/v1/auth/recover/password`, data);
  }

  validatePasswordRecovery(
    data: ValidatePasswordRecoveryRequest,
  ): ValidatePasswordRecoveryResponse {
    return this.post(
      `${this.apiEndpoint}/api/v1/auth/recover/password/${data.tokenId}`,
    );
  }

  updateUserPasswordAfterRecovery(
    data: UpdateUserPasswordAfterRecoveryRequest,
  ): UpdateUserPasswordAfterRecoveryResponse {
    return this.patch(
      `${this.apiEndpoint}/api/v1/auth/recover/password/${data.tokenId}`,
      data,
    );
  }

  /**
   * Проверить, имеется ли разрешение у пользователя на определенное действие
   */
  can() {
    return this.get(`${this.apiEndpoint}/api/v1/auth/permission/can`);
  }

  /**
   * Проверить, требуется ли обновление сессии пользователя
   */
  checkUserSessionNeedUpdate(): CheckUserSessionNeedUpdateResponse {
    return this.get(`${this.apiEndpoint}/api/v1/auth/token/need-update`);
  }
}

export const useAuthApi = createSharedComposable(() => {
  const { NUXT_API_BASE_URL } = useRuntimeConfig().public;

  return new AuthApi(NUXT_API_BASE_URL);
});
