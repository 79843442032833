import type { _Response } from "~/utils/axios-wrapper/types";
import type { ResponseDto } from "~/composables/api/dto/response";

/**
 * Является ли код ответа ошибочным
 * @param code Код ответа
 */
export const isErrorHttp = (code: number): boolean => {
  const _code = Number(code);
  return _code >= 400 && _code <= 599 && _code !== 0;
};

/**
 * Является ли полученный ответ корректным
 * @param response Полученный ответ
 * @return true - Корректный. false - Некорректный.
 */
export const isValidResponse = (
  response: _Response<ResponseDto<any>>,
): boolean => {
  try {
    return !(
      typeof response === "undefined" || isErrorHttp(response?.httpStatus)
    );
  } catch (e) {
    console.error(e);
    return false;
  }
};

/**
 * Является ли полученный ответ некорректным
 * @param response Полученный ответ
 * @return true - Некорректный. false - Корректный.
 */
export function isInvalidResponse(
  response: _Response<ResponseDto<any>>,
): boolean {
  return !isValidResponse(response);
}

export function isRecordNotFound(
  response: _Response<ResponseDto<any>>,
): boolean {
  return response.httpStatus === 404;
}
