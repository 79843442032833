import { AxiosWrapper } from "~/utils/axios-wrapper";
import {
  createCartIdHeader,
  createAuthHeader,
  createReqUpdateAuth,
  createAnonymousUserIdHeader,
} from "~/utils/http/axios/interceptors";
import type { GetCartResponse } from "~/composables/api/dto/get_cart";
import type {
  AddProductToCartRequest,
  AddProductToCartResponse,
} from "~/composables/api/dto/add_product_to_cart";
import type {
  DeleteProductFromCartRequest,
  DeleteProductFromCartResponse,
} from "~/composables/api/dto/delete_product_from_cart";

class CartApi extends AxiosWrapper {
  constructor(apiBaseUrl: string) {
    super({
      apiEndpoint: apiBaseUrl,
      config: {
        withCredentials: true,
      },
      interceptors: {
        request: {
          fulfilled: [
            createAuthHeader,
            createCartIdHeader,
            createAnonymousUserIdHeader,
          ],
        },
        response: {
          rejected: [createReqUpdateAuth],
        },
      },
    });
  }

  getCart(): GetCartResponse {
    return this.get(`${this.apiEndpoint}/api/v1/cart`);
  }

  addProductToCart(data: AddProductToCartRequest): AddProductToCartResponse {
    return this.post(`${this.apiEndpoint}/api/v1/cart/product`, data);
  }

  deleteProductFromCart(
    data: DeleteProductFromCartRequest,
  ): DeleteProductFromCartResponse {
    return this.delete(`${this.apiEndpoint}/api/v1/cart/product`, data);
  }
}

export const useCartApi = createSharedComposable(() => {
  const { NUXT_API_BASE_URL } = useRuntimeConfig().public;

  return new CartApi(NUXT_API_BASE_URL);
});
