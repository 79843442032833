export const enum CookieName {
  CategoryListDisplayAs = "category_list_display_as",
  CategoryProductListDisplayAs = "category_product_list_display_as",
  AllProductsDisplayAs = "all_products_display_as",
  SearchProductsDisplayAs = "search_products_display_as",

  UserAgreement = "user_agreement",

  SafePageLeave = "safe_page_leave",
}
