<script setup lang="ts">
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import { LsEventName } from "~/utils/enum/local_storage_event";
import { useSiteSettingsStore } from "~/stores/site_settings";
import { PublicAsset } from "~/utils/enum/public_asset";
import * as locales from "@nuxt/ui-pro/locale";

useHead({
  meta: [
    { charset: "utf-8" },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    { key: "theme-color", name: "theme-color", content: "light" },
  ],
  link: [{ rel: "icon", href: "/favicon.ico" }],
  htmlAttrs: {
    lang: "ru",
  },
});

const title = "Алвима";
const description =
  "Интернет-магазин Алвима - это большой выбор комплектующих и расходных материалов для проведения работ по установке систем мониторинга транспорта и тахографического контроля.";

useSeoMeta({
  titleTemplate: "%s - интернет-магазин Алвима",
  title: title,
  description: description,
  ogTitle: title,
  ogDescription: description,
  ogImage: PublicAsset.AlvimaLogoPng,
});

const cartStore = useCartStore();
const siteSettingsStore = useSiteSettingsStore();

function setupWindowEvents() {
  localStorage.removeItem(LsEventName.Login);
  localStorage.removeItem(LsEventName.Logout);
  localStorage.removeItem(LsEventName.CartUpdated);

  window.addEventListener("storage", function (event) {
    if (event.key === LsEventName.Login) {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  });

  window.addEventListener("storage", function (event) {
    if (event.key === LsEventName.Logout) {
      window.location.reload();
    }
  });

  window.addEventListener("storage", async function (event) {
    if (event.key === LsEventName.CartUpdated) {
      await cartStore.loadCart();
    }
  });
}

// function serveGsap() {
//   if (!isClient) return;
//
//   gsap.config({
//     nullTargetWarn: false,
//   });
//
//   gsap.registerPlugin(ScrollTrigger);
//
//   gsap.to("[data-speed]", {
//     y: (i, el) =>
//       (1 - Number.parseFloat(el.getAttribute("data-speed"))) *
//       ScrollTrigger.maxScroll(window),
//     ease: "none",
//     scrollTrigger: {
//       start: 0,
//       end: "max",
//       invalidateOnRefresh: true,
//       scrub: 0,
//     },
//   });
// }

onServerPrefetch(async () => {
  await siteSettingsStore.loadSiteSettings();
});

onMounted(async () => {
  setupWindowEvents();
});

// watch(
//   () => route.name,
//   () => {
//     serveGsap();
//   },
//   {
//     immediate: true,
//   },
// );
</script>

<template>
  <UApp :locale="locales['ru']">
    <NuxtLoadingIndicator />

    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </UApp>
</template>

<style>
/* Отключение зума по двойному тапу */
html,
body,
div,
aside,
nav,
footer,
header,
section,
main,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
img,
svg,
span,
label,
input,
textarea,
button,
select,
address {
  touch-action: manipulation;
}
</style>
