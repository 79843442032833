import {
  extractErrorCode,
  newInvalidResponseError,
  useZap,
} from "~/composables/logger/zap";
import { buildSiteSettings } from "~/domain/constructor/site_settings";
import type { SiteSettings } from "~/domain/entity";
import { useSiteSettingsApi } from "~/composables/api/site_settings";
import { isInvalidResponse } from "~/utils/http/response";
import { ApiErrorCode } from "~/utils/enum/api_error_code";
import { numberWithSpaces } from "~/utils/number";
import { getS3Path } from "~/utils/s3";
import { ThematicThemeNewYear } from "~/domain/entity";
import { PublicAsset } from "~/utils/enum/public_asset";
import { isClient } from "@vueuse/core";
import { CookieName } from "~/utils/enum/cookie_name";
import { getExpiry } from "~/utils/date";

type ScrollPosition = {
  x: number;
  y: number;
};

export const useSiteSettingsStore = defineStore("siteSettings", () => {
  const l = useZap("useSiteSettingsStore");

  const cartStore = useCartStore();

  const siteSettingsApi = useSiteSettingsApi();

  const siteSettings = ref<SiteSettings>(buildSiteSettings());

  const is = ref({
    loading: false,
    firstPageLoading: true,
  });

  const historyLength = ref(0);
  const historyScrollTrigger = ref(false);
  const rememberedScrollPosition = ref<null | ScrollPosition>(null);

  async function loadSiteSettings() {
    if (is.value.loading) return;

    is.value.loading = true;

    const _l = l.named("loadSiteSettings");

    try {
      const res = await siteSettingsApi.getSiteSettings();

      if (isInvalidResponse(res)) {
        const errCode = extractErrorCode(res);

        if (errCode === ApiErrorCode.CodeRecordNotFound) {
          return;
        }

        throw newInvalidResponseError(res);
      }

      if (!res.data?.payload?.siteSettings) {
        throw newInvalidResponseError(res);
      }

      siteSettings.value = res.data.payload.siteSettings;
    } catch (e) {
      await _l.error(e);
    } finally {
      is.value.loading = false;
    }
  }

  function incrementHistoryLength() {
    historyLength.value++;
  }

  function rememberScrollPosition() {
    if (!isClient) return;

    const x = window.scrollX - document.documentElement.clientLeft;
    const y = window.scrollY - document.documentElement.clientTop;

    rememberedScrollPosition.value = {
      x: x,
      y: y,
    };
  }

  function restoreScrollPosition() {
    if (!isClient) return;

    if (rememberedScrollPosition.value) {
      const x = rememberedScrollPosition.value.x;
      const y = rememberedScrollPosition.value.y;

      setTimeout(() => {
        window.scrollTo({
          left: x,
          top: y,
        });
      }, 200);

      rememberedScrollPosition.value = null;
    }
  }

  function setHistoryScrollTrigger(v: boolean) {
    historyScrollTrigger.value = v;
  }

  const getFreeShippingCost = computed(
    (): number => Number(siteSettings.value.freeShippingCost) || 0,
  );

  const getFreeShippingCostWithSpaces = computed(() =>
    numberWithSpaces(getFreeShippingCost.value, "."),
  );

  const getFreeShippingDifference = computed(
    () => getFreeShippingCost.value - cartStore.getTotalCartPrice,
  );

  const getFreeShippingDifferenceWithSpaces = computed(() =>
    numberWithSpaces(getFreeShippingCost.value - cartStore.getTotalCartPrice),
  );

  const getFreeShippingReached = computed(
    () => getFreeShippingDifference.value <= 0,
  );

  const getRequisitesExists = computed(
    () => !!siteSettings.value?.requisitesFile,
  );

  const getRequisitesFileName = computed(
    () =>
      `${siteSettings.value?.requisitesFile?.title}.${siteSettings.value?.requisitesFile?.extension}`,
  );

  const getRequisitesFileUrl = computed(
    () => `${getS3Path(siteSettings.value?.requisitesFile || "")}`,
  );

  const getIsLoading = computed(() => is.value.loading);

  const getIsThematicThemeNewYear = computed(
    () => siteSettings.value?.thematicTheme === ThematicThemeNewYear,
  );

  const getAlvimaThematicLogo = computed(() => {
    if (getIsThematicThemeNewYear.value)
      return PublicAsset.AlvimaLogoThematicNewYear;

    return PublicAsset.AlvimaLogo;
  });

  const getHistoryLength = computed(() => historyLength.value);

  const getHistoryScrollTrigger = computed(() => historyScrollTrigger.value);

  const getRememberedScrollPosition = computed(
    () => rememberedScrollPosition.value,
  );

  const getIsFirstPageLoading = computed(() => is.value.firstPageLoading);

  const getAdminIsSafePageLeave = computed((): boolean => {
    const safePageLeaveCookie = useCookie(CookieName.SafePageLeave, {
      path: "/",
      expires: getExpiry(),
    });

    return safePageLeaveCookie.value !== undefined
      ? Boolean(safePageLeaveCookie.value)
      : true;
  });

  return {
    is,
    siteSettings,

    loadSiteSettings,
    incrementHistoryLength,
    rememberScrollPosition,
    restoreScrollPosition,

    setHistoryScrollTrigger,

    getFreeShippingCost,
    getFreeShippingCostWithSpaces,
    getFreeShippingDifference,
    getFreeShippingDifferenceWithSpaces,
    getFreeShippingReached,
    getRequisitesExists,
    getRequisitesFileName,
    getRequisitesFileUrl,
    getIsLoading,
    getIsThematicThemeNewYear,
    getAlvimaThematicLogo,
    getHistoryLength,
    getHistoryScrollTrigger,
    getRememberedScrollPosition,
    getAdminIsSafePageLeave,
    getIsFirstPageLoading,
  };
});
