export function parseDateToFullLocale(date: string | Date): string {
  if (!date) return "";

  let _date = new Date();

  if (typeof date === "string") {
    _date = new Date(date);
  } else {
    _date = date;
  }

  let dd = _date.getDate().toString();
  const mm = getMonthNameInGenitiveCase(_date.getMonth());

  const yyyy = _date.getFullYear();

  if (Number(dd) < 10) {
    dd = "0" + dd;
  }

  return dd + " " + mm + " " + yyyy;
}

export function parseDateToFullLocaleWithTime(date: string | Date): string {
  const fullLocale = parseDateToFullLocale(date);

  if (!fullLocale) return "";

  let _date = new Date();

  if (typeof date === "string") {
    _date = new Date(date);
  } else {
    _date = date;
  }

  let hh = _date.getHours().toString();
  let mm = _date.getMinutes().toString();
  let ss = _date.getSeconds().toString();

  if (Number(hh) < 10) {
    hh = "0" + hh;
  }

  if (Number(mm) < 10) {
    mm = "0" + mm;
  }

  if (Number(ss) < 10) {
    ss = "0" + ss;
  }

  return fullLocale + ", " + hh + ":" + mm + ":" + ss;
}

function getMonthNameInGenitiveCase(month: number) {
  return [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ][month];
}

export function getExpiry(days?: number): Date {
  let _days = 100000;

  if (days && days > 0) {
    _days = days * 24 * 60 * 60 * 1000;
  }

  const expires = new Date();
  expires.setTime(expires.getTime() + _days * 24 * 60 * 60 * 1000);

  return expires;
}
