import {
  extractErrorCode,
  newInvalidResponseError,
  useZap,
} from "~/composables/logger/zap";
import { isInvalidResponse } from "~/utils/http/response";
import { ApiErrorCode } from "~/utils/enum/api_error_code";
import { useCartApi } from "~/composables/api/cart";
import type { AddProductToCartRequest } from "~/composables/api/dto/add_product_to_cart";
import type { DeleteProductFromCartRequest } from "~/composables/api/dto/delete_product_from_cart";
import type { Cart, CartProduct, CustomerProduct } from "~/domain/entity";
import { buildCart } from "~/domain/constructor/cart";
import { createErrorToast } from "~/utils/notification/error";
import { numberWithSpaces } from "~/utils/number";
import type { NuxtApp } from "#app";
import { setResponseCookies } from "~/utils/http/axios/cookie";
import { isClient } from "@vueuse/core";

const maxQuantity = 100_000;

export const useCartStore = defineStore("cartStore", () => {
  const l = useZap("useCartStore");

  const authStore = useAuthStore();
  const cartApi = useCartApi();

  const is = ref({
    loadingUpdateCart: false,
  });

  const cart = ref<Cart>(buildCart());

  function createMaxQuantityLimitToast() {
    createErrorToast(`Максимум ${numberWithSpaces(maxQuantity)}`);
  }

  function resetState() {
    is.value.loadingUpdateCart = false;
    cart.value = buildCart();
  }

  async function loadCart(nuxtApp?: NuxtApp): Promise<number> {
    if (is.value.loadingUpdateCart) return ApiErrorCode.CodeUnknown;

    is.value.loadingUpdateCart = true;

    const _l = l.named("loadCart");
    let errCode = ApiErrorCode.CodeUnknown;

    try {
      let cartIdFromCookie = "";

      if (!isClient) {
        cartIdFromCookie = useCookie("cart_id").value || "";
        cart.value.id = cartIdFromCookie;
      }

      const res = await cartApi.getCart();

      setResponseCookies(res, nuxtApp);

      if (isInvalidResponse(res)) {
        errCode = extractErrorCode(res);
        cart.value = buildCart();

        if (errCode === ApiErrorCode.CodeCartNotFound) {
          return errCode;
        }

        throw newInvalidResponseError(res);
      }

      cart.value = res.data.payload.cart ? res.data.payload.cart : buildCart();

      return ApiErrorCode.CodeNoError;
    } catch (e) {
      await _l.error(e);
      return errCode;
    } finally {
      is.value.loadingUpdateCart = false;
    }
  }

  async function unsafeAddProductToCart(
    data: AddProductToCartRequest,
    isRepeat?: boolean,
  ): Promise<number> {
    const _l = l.named("unsafeAddProductToCart");
    let errCode = ApiErrorCode.CodeUnknown;

    try {
      for (let i = 0; i < data.cartProductList.length; i++) {
        if (!data.cartProductList[i]) continue;

        // @ts-expect-error
        if (data.cartProductList[i].quantity > maxQuantity) {
          // @ts-expect-error
          data.cartProductList[i].quantity = maxQuantity;
        }
      }

      const res = await cartApi.addProductToCart(data);

      if (isInvalidResponse(res)) {
        errCode = Number(res.data.error);

        if (res.httpStatus === 422 && !isRepeat) {
          authStore.setUserId("");
          return unsafeAddProductToCart(data, true);
        }

        throw newInvalidResponseError(res);
      }

      if (isRepeat) {
        authStore.needUpdateAnonymousUserId();
      }

      cart.value = res.data.payload.cart ? res.data.payload.cart : buildCart();

      return ApiErrorCode.CodeNoError;
    } catch (e) {
      await _l.error(e);
      return errCode;
    }
  }

  async function addProductToCart(
    data: AddProductToCartRequest,
  ): Promise<number> {
    const _l = l.named("addProductToCart");
    let errCode = ApiErrorCode.CodeUnknown;

    if (is.value.loadingUpdateCart) return errCode;

    try {
      is.value.loadingUpdateCart = true;

      errCode = await unsafeAddProductToCart(data);
    } catch (e) {
      await _l.error(e);
    } finally {
      is.value.loadingUpdateCart = false;
    }

    return errCode;
  }

  async function addOneProductToCart(
    productId: string,
    quantity: number,
  ): Promise<number> {
    const curQuantity = getProductQuantity(productId);

    if (curQuantity === maxQuantity) {
      createMaxQuantityLimitToast();
      return ApiErrorCode.CodeNoError;
    }

    return await addProductToCart({
      cartProductList: [
        {
          productId: productId,
          quantity: quantity,
        },
      ],
    });
  }

  async function deleteProductFromCart(
    data: DeleteProductFromCartRequest,
  ): Promise<number> {
    const _l = l.named("deleteProductFromCart");
    let errCode = ApiErrorCode.CodeUnknown;

    if (is.value.loadingUpdateCart) return errCode;

    try {
      is.value.loadingUpdateCart = true;

      const res = await cartApi.deleteProductFromCart(data);

      if (isInvalidResponse(res)) {
        errCode = Number(res.data.error);

        if (res.httpStatus === 422) {
          authStore.setUserId("");
        }

        throw newInvalidResponseError(res);
      }

      cart.value = res.data.payload.cart ? res.data.payload.cart : buildCart();

      errCode = ApiErrorCode.CodeNoError;
    } catch (e) {
      await _l.error(e);
    } finally {
      is.value.loadingUpdateCart = false;
    }

    return errCode;
  }

  async function deleteOneProductFromCart(
    productId: string,
    quantity: number,
  ): Promise<number> {
    return await deleteProductFromCart({
      cartProductList: [
        {
          productId: productId,
          quantity: quantity,
        },
      ],
    });
  }

  async function fullDeleteOneProductFromCart(
    productId: string,
  ): Promise<number> {
    return await deleteOneProductFromCart(
      productId,
      getProductQuantity(productId),
    );
  }

  async function reInitState() {
    resetState();
    await loadCart();
  }

  async function modelWatcher(
    productId: string,
    newValue: any,
    model: Ref<number>,
  ) {
    newValue = Math.floor(Number(newValue));

    const curQuantity = getProductQuantity(productId);

    if (newValue <= 0) {
      return;
    }

    if (newValue > maxQuantity) {
      newValue = maxQuantity;
      model.value = maxQuantity;
      createMaxQuantityLimitToast();
    }

    if (newValue === curQuantity) {
      return;
    }

    if (newValue > curQuantity) {
      addProductToCart({
        cartProductList: [
          {
            productId: productId,
            quantity: newValue - curQuantity,
          },
        ],
      });
    } else {
      deleteProductFromCart({
        cartProductList: [
          {
            productId: productId,
            quantity: curQuantity - newValue,
          },
        ],
      });
    }
  }

  function getProductQuantity(productId: string): number {
    if (!cart.value.cartProductList) return 0;

    const idx = cart.value.cartProductList.findIndex(
      (p) => p?.product?.id === productId,
    );

    if (idx !== -1) {
      return (cart.value.cartProductList[idx] as CartProduct).quantity;
    }

    return 0;
  }

  function getIsProductInCart(productId: string): boolean {
    return getProductQuantity(productId) > 0;
  }

  function getTotalProductPrice(productId: string): number {
    if (!cart.value.cartProductList) return 0;

    const idx = cart.value.cartProductList.findIndex(
      (p) => p?.product?.id === productId,
    );

    if (idx !== -1) {
      const p = cart.value.cartProductList[idx] as CartProduct;

      return p.quantity * (p.product as CustomerProduct).personalPrice;
    }

    return 0;
  }

  return {
    is,
    cart,

    loadCart,
    modelWatcher,
    addOneProductToCart,
    deleteOneProductFromCart,
    fullDeleteOneProductFromCart,
    reInitState,

    getProductQuantity,
    getIsProductInCart,
    getTotalProductPrice,

    getCantCartManipulate: computed((): boolean => {
      if (authStore.getIsUpdateUserSessionLoading) return true;

      if (is.value.loadingUpdateCart) return true;

      return false;
    }),

    getIsLoadingUpdateCart: computed(() => is.value.loadingUpdateCart),

    getCartLength: computed((): number => {
      if (!cart.value.cartProductList) return 0;

      return cart.value.cartProductList.length;
    }),

    getCartTotalProductListLength: computed((): number => {
      if (!cart.value?.cartProductList) return 0;

      return cart.value.cartProductList.reduce(
        (acc, p) => acc + (p?.quantity || 0),
        0,
      );
    }),

    getCartProductList: computed(() => cart.value.cartProductList),

    getTotalCartPrice: computed((): number => {
      if (!cart.value?.cartProductList) return 0;

      return cart.value.cartProductList.reduce(
        // @ts-expect-error
        (acc, p) => acc + p?.quantity * (p?.product?.personalPrice || 0),
        0,
      );
    }),

    getCartIsEmpty: computed((): boolean => {
      if (!cart.value.cartProductList) return true;

      return cart.value.cartProductList.length === 0;
    }),
  };
});
