import payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44 from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_xrNL5Oibaxini5_x4JbjQcR0LQWoPOfFCUtVYLQFJOM from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.4.1_@types+node@22.5.4_db0@0.3.1_eslint@9.23.0_jiti@2.4.2_e0c2fe21b58e4305bc04c7535c91c077/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_sdYRTwVWdVEMJEhgi9MnM8rBkAvI5Tjup6b4Oy5kNiY from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.4.1_@types+node@22.5.4_db0@0.3.1_eslint@9.23.0_jiti@2.4.2_e0c2fe21b58e4305bc04c7535c91c077/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_fmtEL3zk24ZZEKYtywwWHIf_tUhzrF27mPsmX3Ygq94 from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.4.1_@types+node@22.5.4_db0@0.3.1_eslint@9.23.0_jiti@2.4.2_e0c2fe21b58e4305bc04c7535c91c077/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A from "/app/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_wyrZJPeam3soKLhe5oF20dj1xWhO0LMYIhSFVGxC9Y4 from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.4.1_@types+node@22.5.4_db0@0.3.1_eslint@9.23.0_jiti@2.4.2_e0c2fe21b58e4305bc04c7535c91c077/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_KqMxL_bERV9JL2_uiV6W7_yh93Fukk53q4xfyjqk9VY from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.4.1_@types+node@22.5.4_db0@0.3.1_eslint@9.23.0_jiti@2.4.2_e0c2fe21b58e4305bc04c7535c91c077/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_bG8nDWXf4csJeYgAVW40V__vbDj4jz_NZGmib48mROQ from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.4.1_@types+node@22.5.4_db0@0.3.1_eslint@9.23.0_jiti@2.4.2_e0c2fe21b58e4305bc04c7535c91c077/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_sF5tRcGpBxoGXnDpoGIPzFOJ3u4NmwuDCt0GftN068Y from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.4.1_@types+node@22.5.4_db0@0.3.1_eslint@9.23.0_jiti@2.4.2_e0c2fe21b58e4305bc04c7535c91c077/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/app/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_D0DkBPAFKi1Ewg7jEboZy1stfETejarZFx24ud9_4ZA from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.4.1_@types+node@22.5.4_db0@0.3.1_eslint@9.23.0_jiti@2.4.2_e0c2fe21b58e4305bc04c7535c91c077/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_O5md9yxLMxPp2kXzNImCs57GI6ofGYN3cWX5Hib0G5U from "/app/node_modules/.pnpm/yandex-metrika-module-nuxt3@1.5.3_magicast@0.3.5/node_modules/yandex-metrika-module-nuxt3/dist/runtime/plugin.mjs";
import colors_ub9UYn9cngKf4tF5oB4xrNnVDbkgFFazOiKOO0vxRMs from "/app/node_modules/.pnpm/@nuxt+ui@3.0.2_@babel+parser@7.26.10_axios@1.8.4_change-case@5.4.4_db0@0.3.1_embla-caro_dbecd8f6ce8da974007ab1f8c83742cd/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_eNx0RlxKkxnTgWl2xtuHe5_rfL_OjR0pVLDpLKpkyqQ from "/app/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.2_@types+node@22.5.4_jiti@2.4.2_lightningcss@_62ced1d914ae39d3ab96902466259107/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import mitt_JIz7P7qE1X2vtYIHm1jPLyY3mPdeNS4fJ9GIDcwzSXA from "/app/app/plugins/mitt.ts";
export default [
  payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44,
  revive_payload_client_xrNL5Oibaxini5_x4JbjQcR0LQWoPOfFCUtVYLQFJOM,
  unhead_sdYRTwVWdVEMJEhgi9MnM8rBkAvI5Tjup6b4Oy5kNiY,
  router_fmtEL3zk24ZZEKYtywwWHIf_tUhzrF27mPsmX3Ygq94,
  _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A,
  payload_client_wyrZJPeam3soKLhe5oF20dj1xWhO0LMYIhSFVGxC9Y4,
  navigation_repaint_client_KqMxL_bERV9JL2_uiV6W7_yh93Fukk53q4xfyjqk9VY,
  check_outdated_build_client_bG8nDWXf4csJeYgAVW40V__vbDj4jz_NZGmib48mROQ,
  chunk_reload_client_sF5tRcGpBxoGXnDpoGIPzFOJ3u4NmwuDCt0GftN068Y,
  plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_D0DkBPAFKi1Ewg7jEboZy1stfETejarZFx24ud9_4ZA,
  plugin_O5md9yxLMxPp2kXzNImCs57GI6ofGYN3cWX5Hib0G5U,
  colors_ub9UYn9cngKf4tF5oB4xrNnVDbkgFFazOiKOO0vxRMs,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  plugin_eNx0RlxKkxnTgWl2xtuHe5_rfL_OjR0pVLDpLKpkyqQ,
  mitt_JIz7P7qE1X2vtYIHm1jPLyY3mPdeNS4fJ9GIDcwzSXA
]