export const enum PageName {
  Home = "Home",

  AuthRecovery = "AuthRecovery",
  AuthRecoveryToken = "AuthRecoveryToken",
  AuthLogin = "AuthLogin",
  AuthRegister = "AuthRegister",
  AuthLogout = "AuthLogout",

  Redirect = "Redirect",

  CategoryList = "CategoryList",
  CategorySlug = "CategorySlug",
  CategoryProductSlug = "CategoryProductSlug",

  ProductSlug = "ProductSlug",

  Contact = "Contact",
  Delivery = "Delivery",
  UserAgreement = "UserAgreement",
  ApplicationConditions = "ApplicationConditions",
  Search = "Search",

  Account = "Account",
  AccountConfirmEmail = "AccountConfirmEmail",
  AccountConfirmEmailToken = "AccountConfirmEmailToken",
  AccountOrderList = "AccountOrderList",
  AccountOrderId = "AccountOrderId",
  AccountContact = "AccountContact",
  AccountPassword = "AccountPassword",
  AccountFavorites = "AccountFavorites",

  Cart = "Cart",

  Checkout = "Checkout",
  CheckoutIdSuccess = "CheckoutIdSuccess",

  AllProducts = "AllProducts",

  // --- ADMIN

  AdminHome = "AdminHome",

  AdminOrderList = "AdminOrderList",
  AdminOrderId = "AdminOrderId",

  AdminCategoryList = "AdminCategoryList",
  AdminCategoryCreate = "AdminCategoryCreate",
  AdminCategoryUpdate = "AdminCategoryUpdate",
  AdminCategoryUpdateProductList = "AdminCategoryUpdateProductList",

  AdminProductList = "AdminProductList",
  AdminProductCreate = "AdminProductCreate",
  AdminProductCreateRelations = "AdminProductCreateRelations",
  AdminProductCreateCustomerGroupPrice = "AdminProductCreateCustomerGroupPrice",
  AdminProductCreateMultimedia = "AdminProductCreateMultimedia",
  AdminProductUpdate = "AdminProductUpdate",
  AdminProductUpdateRelations = "AdminProductUpdateRelations",
  AdminProductUpdateCustomerGroupPrice = "AdminProductUpdateCustomerGroupPrice",
  AdminProductUpdateMultimedia = "AdminProductUpdateMultimedia",

  AdminUserList = "AdminUserList",
  AdminUserOrderList = "AdminUserOrderList",
  AdminUserCreate = "AdminUserCreate",
  AdminUserCreateRelations = "AdminUserCreateRelations",
  AdminUserUpdate = "AdminUserUpdate",
  AdminUserUpdateRelations = "AdminUserUpdateRelations",

  AdminCustomerGroupList = "AdminCustomerGroupList",
  AdminCustomerGroupCreate = "AdminCustomerGroupCreate",
  AdminCustomerGroupUpdate = "AdminCustomerGroupUpdate",
  AdminCustomerGroupId = "AdminCustomerGroupId",

  AdminSiteSettings = "AdminSiteSettings",

  AdminIntegratorRequestList = "AdminIntegratorRequestList",
}
